import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

import { userService } from './UserService';

export const contactService = {
    sendRequest,
};

function sendRequest(data) {
    const requestData = {
        email: data.email,
        subject: data.subject,
        comment: data.comment,
        fullName: data.fullName,
        user: userService.isLogged() ? '' : ''
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/contact/send', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
        };
    }
    else if (response.data.errorMessage) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}