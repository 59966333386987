import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

import { reminderService } from '../services/ReminderService';
import { groupService } from '../services/GroupService';

const storageObjectLiveTime = 0.25; // in hours -> 15 minutes
const taskStorageKeyDefault = 'taskList';
const taskStorageKeySearch = 'taskListSearch';
const taskStorageKeyLiveTime = 'taskLiveTime';
const taskStorageKeys = ['taskListSearch', taskStorageKeyDefault, taskStorageKeyLiveTime];

export const taskService = {
    add,
    get,
    update,
    del,
    list,
    formatData,
    clearStorage
};

function clearStorage(keysForDelete) {
    if (keysForDelete.length > 0) {
        keysForDelete.forEach(k => localStorage.removeItem(k))
    } else {
        taskStorageKeys.forEach(k => localStorage.removeItem(k))
    }
}

function add(data) {
    const requestData = data;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(taskStorageKeys)
    reminderService.clearStorage([])
    groupService.clearStorage([])

    return axios.post(config.apiUrl + '/task', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/task/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function update(data) {
    const requestData = data;
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(taskStorageKeys)
    reminderService.clearStorage([])
    groupService.clearStorage([])

    return axios.patch(config.apiUrl + '/task/' + requestData.id, requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function del(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(taskStorageKeys)
    reminderService.clearStorage([])
    groupService.clearStorage([])

    return axios.delete(config.apiUrl + '/task/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };
    const linkParams = data !== undefined && data.linkParams !== undefined ? '?' + data.linkParams : '';

    const localStorageKey = (data.storageKey !== undefined && data.storageKey !== "") ? data.storageKey : taskStorageKeyDefault;

    //clean storage every 15 min
    if (localStorage.getItem(taskStorageKeyLiveTime) !== null) {
        if (new Date().getTime() - localStorage.getItem(taskStorageKeyLiveTime) > storageObjectLiveTime * 60 * 60 * 1000 ) {
            clearStorage(taskStorageKeys)
        }
    }
    // delete because search
    // eslint-disable-next-line
    if (localStorageKey == taskStorageKeySearch) {
        clearStorage(taskStorageKeys)
    }

    if (localStorage.getItem(localStorageKey) !== null) {

        //alert(1)

        return new Promise(function(resolve, reject) {
            resolve(JSON.parse(localStorage.getItem(localStorageKey)));
        });
    } else {
        return axios.get(config.apiUrl + '/tasks' + linkParams, requestOptions)
            .then(handleResponse)
            .then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    localStorage.setItem(localStorageKey, JSON.stringify(response))
                    localStorage.setItem(taskStorageKeyLiveTime, new Date().getTime())
                }
                return response
            })
            .catch(handleError);
    }
}

function handleResponse(response) {

    if (response.data.status === 200) {
        return response.data;
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}


function formatData(response) {
    //Fields from Task form: name: "", description: "", group: "", startDate: "", endDate: "", remFirstName: "", remFirstDate: "", remSecondName: "", remSecondDate: "", remThirdName: "", remThirdDate: ""
    const formattedResponse = {
        data: {
            name: response.name,
            description: response.description,
            group: response.group,
            startDate: response.startDate,
            endDate: response.startDate,
            remFirstName: response.reminders.length >= 1 ? response.reminders[0].message : "",
            remFirstDate: response.reminders.length >= 1 ? response.reminders[0].reminderDate : "",
            remSecondName: response.reminders.length >= 2 ? response.reminders[1].message : "",
            remSecondDate: response.reminders.length >= 2 ? response.reminders[1].reminderDate : "",
            remThirdName: response.reminders.length >= 3 ? response.reminders[2].message : "",
            remThirdDate: response.reminders.length >= 3 ? response.reminders[2].reminderDate : ""
        }
    };


    //alert(JSON.stringify(response));
    //alert(JSON.stringify(formattedResponse));

    return formattedResponse;
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}