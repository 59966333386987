import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import QRCode from 'react-qr-code';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

import { fileService } from '../../services/FileService';

function TaskShow(props) {

    const record = props.record;
    const [files, setFiles] = useState([]);

    useEffect(() => {
        async function getFiles() {
            if (record.files.length > 0) {
                setFiles(record.files.map(item => ({name: item.name, url: item.url})));
            } else {
                const urlParams = new URLSearchParams({task: record.id})
                const response = await fileService.list({linkParams: urlParams});
                // eslint-disable-next-line
                if (response.status == 200) {
                    setFiles(response.data.items.map(item => ({name: item.name, url: item.url})));
                }
            }
        }
        getFiles();
        // eslint-disable-next-line
    }, []);

    return(
        <div>
            <div className="row">
                <div className="col-md-5">
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Name: </span>
                        <span>{record.name}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Serial number: </span>
                        <span>{record.serialNumber}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Group: </span>
                        <span>{record.groupName}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Description: </span>
                        <span>{record.description}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Notes: </span>
                        <span>{record.note}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">Start date: </span>
                        <span>{Moment(record.startDate.date).format('DD.MM.YYYY')}</span>
                    </div>
                    <div className="col pl-0 pr-0 pb-3">
                        <span className="font-weight-bold">End date: </span>
                        <span>{Moment(record.endDate.date).format('DD.MM.YYYY')}</span>
                    </div>
                </div>
                <div className="col-md-7 hv-center">
                    <QRCode value={"Name: " + record.name + " | SerialNR: " + (record.serialNumber ? record.serialNumber : "") + " | Control/Revision Date: " + Moment(record.endDate.date).format('DD.MM.YYYY') + " | Notes: " + (record.note ? record.note : "") } />
                </div>
            </div>
            <div className="col pl-0 pr-0 pb-3">
                <span className="font-weight-bold">Images: </span>
                <span>
                    <hr/>
                    {
                        files.length > 0 ?
                            files.map((fl, index) =>
                                <div key={index}>
                                <div className="col">
                                    <div className="mb-2 font-italic">{ fl.name }</div>
                                    <img className="img-fluid" src={ fl.url } alt="" />
                                </div>
                                <hr/>
                                </div>
                            )
                        :
                            <>
                            <div className="col text-muted font-italic mt-2">No image added</div>
                            <hr/>
                            </>
                    }
                </span>
            </div>
            <div className="col pl-0 pr-0 pb-3">
                <span className="font-weight-bold">Reminders: </span>
                <span>
                    <hr/>
                    {
                        record.reminders.length > 0 ?
                            record.reminders.map((task, index) =>
                                <div key={index}>
                                    <div className="col font-italic">
                                        { Moment(task.reminderDate.date).format('DD.MM.YYYY') }
                                        { task.sentDate ? <FontAwesomeIcon icon={faCalendarCheck} className="text-success ml-3" /> : '' }
                                        { task.sentDate ? <span className="text-success"> { Moment(task.sentDate.date).format('DD.MM.YYYY') } </span> : '' }
                                    </div>
                                    <div className="col">{ task.message }</div>
                                    <hr/>
                                </div>
                            )
                        :
                            <>
                            <div className="col text-muted font-italic mt-2">No reminders added</div>
                            <hr/>
                            </>
                    }
                </span>
            </div>
        </div>
    )
}

export default TaskShow;