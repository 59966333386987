import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

import { reminderService } from '../services/ReminderService';
import { taskService } from '../services/TaskService';

const storageObjectLiveTime = 0.25; // in hours -> 15 minutes
const groupStorageKeyDefault = 'groupList';
const groupStorageKeyLiveTime = 'groupLiveTime';
const groupStorageKeys = [groupStorageKeyDefault, groupStorageKeyLiveTime];

export const groupService = {
    add,
    get,
    update,
    del,
    list,
    clearStorage
};

function clearStorage(keysForDelete) {
    if (keysForDelete.length > 0) {
        keysForDelete.forEach(k => localStorage.removeItem(k))
    } else {
        groupStorageKeys.forEach(k => localStorage.removeItem(k))
    }
}

function add(data) {
    const requestData = {
        name: data.name,
        description: data.description,
        reminders: data.reminders
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(groupStorageKeys)
    reminderService.clearStorage([])

    return axios.post(config.apiUrl + '/group', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/group/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function update(data) {
    const requestData = {
        id: data.id,
        name: data.name,
        description: data.description,
        reminders: data.reminders
    };
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(groupStorageKeys)
    reminderService.clearStorage([])
    taskService.clearStorage([])

    return axios.patch(config.apiUrl + '/group/' + requestData.id, requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function del(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(groupStorageKeys)
    reminderService.clearStorage([])
    taskService.clearStorage([])

    return axios.delete(config.apiUrl + '/group/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    const localStorageKey = (data.storageKey !== undefined && data.storageKey !== "") ? data.storageKey : groupStorageKeyDefault;

    //clean storage every 15 min
    if (localStorage.getItem(groupStorageKeyLiveTime) !== null) {
        if (new Date().getTime() - localStorage.getItem(groupStorageKeyLiveTime) > storageObjectLiveTime * 60 * 60 * 1000 ) {
            clearStorage(groupStorageKeys)
        }
    }

    if (localStorage.getItem(localStorageKey) !== null) {

        //alert(1)

        return new Promise(function(resolve, reject) {
            resolve(JSON.parse(localStorage.getItem(localStorageKey)));
        });
    } else {
        return axios.get(config.apiUrl + '/groups', requestOptions)
            .then(handleResponse)
            .then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    localStorage.setItem(localStorageKey, JSON.stringify(response))
                    localStorage.setItem(groupStorageKeyLiveTime, new Date().getTime())
                }
                return response
            })
            .catch(handleError);
    }
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}