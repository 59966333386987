import React, {useState} from 'react';
import { withRouter, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faLayerGroup, faTasks, faBell, faChevronCircleLeft, faChevronCircleRight, faLockOpen } from "@fortawesome/free-solid-svg-icons";

import './LeftNav.css';

import { userService } from '../../services/UserService';

function LeftNav(props) {

    const [state , setState] = useState({
        active : true
    })

    const toggleClass = () => {
        const currentState = state.active;
        setState({ active: !currentState });
    }

    const redirectToLogin = (e) => {
        userService.logout(true);
        props.history.push('/login');
        window.location.reload(true);
    }

    return(
        <div className="bg-light d-none d-md-block">

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Link id="sidebarCollapse" to="#" onClick={toggleClass} title={state.active ? 'Show sidebar' : 'Hide sidebar'} className="col pr-0 pl-0">
                    <FontAwesomeIcon icon={ state.active ? faChevronCircleRight : faChevronCircleLeft } size="2x" className="float-right" />
                </Link>
            </nav>

            <nav id="sidebar" className={ state.active ? 'navbar navbar-expand-lg navbar-light bg-light pl-0 pr-0 active' : 'navbar navbar-expand-lg navbar-light bg-light pl-0 pr-0' }>
                <div className="sidebar-sticky pl-1 pr-0 col">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link className="nav-link active align-middle" to="/dashboard">
                                <FontAwesomeIcon icon={ faChartBar } className="mr-2" />
                                <span className={ state.active ? 'd-none' : 'align-middle' }>Dashboard</span> <span className="sr-only">(current)</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/groups">
                                <FontAwesomeIcon icon={ faLayerGroup } className="mr-2" />
                                <span className={ state.active ? 'd-none' : '' }>Groups</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/tasks">
                                <FontAwesomeIcon icon={ faTasks } className="mr-2" />
                                <span className={ state.active ? 'd-none' : '' }>Tasks</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/reminders">
                                <FontAwesomeIcon icon={ faBell } className="mr-2" />
                                <span className={ state.active ? 'd-none' : '' }>Reminders</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="dropdown-divider"></div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#" onClick={e => redirectToLogin(e)}>
                                <FontAwesomeIcon icon={faLockOpen} size="2x" className="mr-2" />
                                <span className={ state.active ? 'd-none' : '' }>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

            {/*
            <nav id="sidebar" className={ state.active ? 'active' : '' }>
                <div className="sidebar-header">
                    <h3>Bootstrap Sidebar</h3>
                    <strong>BS</strong>
                </div>

                <ul className="list-unstyled components">
                    <li className="active">
                        <Link to="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <FontAwesomeIcon icon={faHome} />
                            <span>Home</span>
                        </Link>
                        <ul className="collapse list-unstyled" id="homeSubmenu">
                            <li>
                                <Link to="#">Home 1</Link>
                            </li>
                            <li>
                                <Link to="#">Home 2</Link>
                            </li>
                            <li>
                                <Link to="#">Home 3</Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="#">
                            <FontAwesomeIcon icon={faBriefcase} />
                            About
                        </Link>
                        <Link to="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <FontAwesomeIcon icon={faCopy} />
                            Pages
                        </Link>
                        <ul className="collapse list-unstyled" id="pageSubmenu">
                            <li>
                                <Link to="#">Page 1</Link>
                            </li>
                            <li>
                                <Link to="#">Page 2</Link>
                            </li>
                            <li>
                                <Link to="#">Page 3</Link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </nav>
            */}

        </div>

    )
}

export default withRouter(LeftNav);