import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Moment from 'moment'
import QRCode from 'react-qr-code';

import './TaskForm.css';
import 'react-datepicker/dist/react-datepicker.css'
import { taskService } from '../../services/TaskService';
import { groupService } from '../../services/GroupService';
import { fileService } from '../../services/FileService';

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

const taskNewValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    group: Yup.string().required("Name is required"),
    startDate: Yup.date().min('2000-01-01', "Start date could not be before 01.01.2000").required("Start date is required"),
    endDate: Yup.date().required("End date is required").when('startDate', (startDate, schema) => (startDate && schema.min(startDate, "End date should not be before start date"))),
    remFirstDate: Yup.date()
        .when('startDate', (startDate, schema) => (startDate && schema.min(startDate, "Reminder should not be before start date")))
        .when('endDate', (endDate, schema) => (endDate && schema.max(endDate, "Reminder should not be after end date"))),
    remSecondDate: Yup.date()
        .when('startDate', (startDate, schema) => (startDate && schema.min(startDate, "Reminder should not be before start date")))
        .when('endDate', (endDate, schema) => (endDate && schema.max(endDate, "Reminder should not be after end date"))),
    remThirdDate: Yup.date()
        .when('startDate', (startDate, schema) => (startDate && schema.min(startDate, "Reminder should not be before start date")))
        .when('endDate', (endDate, schema) => (endDate && schema.max(endDate, "Reminder should not be after end date"))),
    image: Yup.mixed()
        .test(
            "fileFormat",
            "Unsupported Format",
            value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        )
        .test(
            "fileSize",
            "File too large",
            value => !value || (value && value.size <= FILE_SIZE)
        )
});
const apiErrors = [];

function TaskForm(props) {

    const isEditMode = !!props.match.params.id;

    const defaultData = {
        id: "",
        name: "",
        serialNumber: "",
        description: "",
        note: "",
        group: "",
        startDate: "",
        endDate: "",
        image: "",
        remFirstId: "",
        remFirstName: "",
        remFirstDate: "",
        remSecondId: "",
        remSecondName: "",
        remSecondDate: "",
        remThirdId: "",
        remThirdName: "",
        remThirdDate: ""
    }
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [task, setTask] = useState(defaultData);
    const [thumb, setThumb] = useState(null);

    useEffect(() => {
        let unmounted = false;
        async function getGroups() {
            const response = await groupService.list({});
            if (!unmounted) {
                setGroups(response.data.items.map(item => ({label: item.name, value: item.id})));
                setLoading(false);
            }
        }
        getGroups();
        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (isEditMode) {
            async function getTask() {
                const responseTaskData = await taskService.get({id: props.match.params.id}).then(respData => respData);

                if (responseTaskData.status !== 200) {
                    props.history.push('/tasks')
                } else {
                    const responseTask = responseTaskData.data;
                    const taskObject = {
                        id: responseTask.id,
                        name: responseTask.name,
                        serialNumber: responseTask.serialNumber,
                        description: responseTask.description,
                        note: responseTask.note,
                        group: responseTask.group,
                        startDate: new Date(Moment(responseTask.startDate.date).toLocaleString()),
                        endDate: new Date(Moment(responseTask.endDate.date).toLocaleString()),
                        image: "",
                        remFirstId: responseTask.reminders.length >= 1 ? responseTask.reminders[0].id : "",
                        remFirstName: responseTask.reminders.length >= 1 ? responseTask.reminders[0].message : "",
                        remFirstDate: responseTask.reminders.length >= 1 ? new Date(Moment(responseTask.reminders[0].reminderDate.date).toLocaleString()) : "",
                        remSecondId: responseTask.reminders.length >= 2 ? responseTask.reminders[1].id : "",
                        remSecondName: responseTask.reminders.length >= 2 ? responseTask.reminders[1].message : "",
                        remSecondDate: responseTask.reminders.length >= 2 ? new Date(Moment(responseTask.reminders[1].reminderDate.date).toLocaleString()) : "",
                        remThirdId: responseTask.reminders.length >= 3 ? responseTask.reminders[2].id : "",
                        remThirdName: responseTask.reminders.length >= 3 ? responseTask.reminders[2].message : "",
                        remThirdDate: responseTask.reminders.length >= 3 ? new Date(Moment(responseTask.reminders[2].reminderDate.date).toLocaleString()) : ""
                    };
                    setTask(taskObject)

                    if (responseTask.files.length >= 1) {
                        setThumb(responseTask.files[0].url)
                    }

                }
            }
            getTask();
        }
        // eslint-disable-next-line
    }, []);

    return(
        <Formik
            initialValues = { task }
            enableReinitialize={true}
            validationSchema={taskNewValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    id: values.id,
                    name: values.name,
                    serialNumber: values.serialNumber,
                    description: values.description,
                    note: values.note,
                    group: values.group,
                    startDate: Moment(values.startDate).toLocaleString(),
                    endDate: Moment(values.endDate).toLocaleString(),
                    image: values.image,
                    reminders: [
                        {id: values.remFirstId, message: values.remFirstName, reminderDate: values.remFirstDate ? Moment(values.remFirstDate).toLocaleString() : null},
                        {id: values.remSecondId, message: values.remSecondName, reminderDate: values.remSecondDate ? Moment(values.remSecondDate).toLocaleString() : null},
                        {id: values.remThirdId, message: values.remThirdName, reminderDate: values.remThirdDate ? Moment(values.remThirdDate).toLocaleString() : null},
                    ]
                }

                isEditMode ?
                    taskService.update(requestData)
                        .then(function (response) {
                            if (response.status === 200) {

                                if (requestData.image) {
                                    const requestDataFile = {
                                        task: requestData.id,
                                        image: requestData.image
                                    }
                                    fileService.upload(requestDataFile)
                                }

                                props.history.push('/tasks');
                                //window.location.reload(true);
                            } else if (response.errorMessage) {
                                setFieldError('name', response.errorMessage);
                                apiErrors.push('name');
                            } else {
                                setFieldError('name', 'API - Not valid data');
                                apiErrors.push('name');
                            }
                        })
                :
                    taskService.add(requestData)
                        .then(function (response) {
                            if (response.status === 200) {

                                if (requestData.image) {
                                    const requestDataFile = {
                                        task: response.data.id,
                                        image: requestData.image
                                    }
                                    fileService.upload(requestDataFile)
                                }

                                props.history.push('/tasks');
                                //window.location.reload(true);
                            } else if (response.errorMessage) {
                                setFieldError('name', response.errorMessage);
                                apiErrors.push('name');
                            } else {
                                setFieldError('name', 'API - Not valid data');
                                apiErrors.push('name');
                            }
                        })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with added data";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card task-new-card col-lg-8 hv-center">
                        <h2 className="mb-5">{isEditMode ? 'Edit: ' + values.name : 'Add New Task'}</h2>
                        <Form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group form-row text-left">
                                <div className="col-md-8">
                                   <label htmlFor="inputName" className={ errors.name ? 'text-danger' : '' }>Name</label>
                                   <input type="text"
                                          name="name"
                                          className={ errors.name ? 'form-control is-invalid' : 'form-control' }
                                          id="name"
                                          aria-describedby="nameHelp"
                                          placeholder="Enter task name"
                                          value={values.name ? values.name : ''}
                                          onChange={handleChange}
                                   />
                                   <span className="text-danger">{errors.name ? errors.name : null}</span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="selectGroup" className={ errors.group ? 'text-danger' : '' }>Group</label>
                                    <select
                                        disabled={ loading }
                                        name="group"
                                        className={ errors.group ? 'form-control is-invalid' : 'form-control' }
                                        id="group"
                                        aria-describedby="groupHelp"
                                        placeholder="Select group"
                                        value={values.group}
                                        onChange={handleChange}
                                    >
                                        <option key="" value="">-- Select group --</option>
                                        {groups.map(group => (
                                            <option key={group.value} value={group.value}>
                                                {group.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.group ? errors.group : null}</span>
                                </div>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col-md-6">
                                    <label htmlFor="inputStartDate" className={ errors.startDate ? 'text-danger' : '' }>Start date</label>
                                    <DatePicker
                                        selected={values.startDate}
                                        name="startDate"
                                        className={ errors.startDate ? 'form-control is-invalid' : 'form-control' }
                                        id="startDate"
                                        placeholderText="Enter start date. Format: dd.mm.yyyy"
                                        value={values.startDate}
                                        onChange={date => setFieldValue('startDate', date ? date : "")}
                                        showWeekNumbers
                                        dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.startDate ? errors.startDate : null}</span>
                                </div>
                                <div className="col-md-6">
                                        <label htmlFor="inputEndDate" className={ errors.endDate ? 'text-danger' : '' }>End date</label>
                                        <DatePicker
                                            selected={values.endDate}
                                            name="endDate"
                                            className={ errors.endDate ? 'form-control is-invalid' : 'form-control' }
                                            id="endDate"
                                            placeholderText="Enter start date. Format: dd.mm.yyyy"
                                            value={values.endDate}
                                            onChange={date => setFieldValue('endDate', date ? date : "")}
                                            showWeekNumbers
                                            dateFormat="dd.MM.yyyy"
                                        />
                                        <span className="text-danger">{errors.endDate ? errors.endDate : null}</span>
                                    </div>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col-md-6">
                                        <div className="col-md-12 p-0">
                                            <label htmlFor="inputSerialNumber">Serial number</label>
                                            <input type="text"
                                                   name="serialNumber"
                                                   className="form-control"
                                                   id="serialNumber"
                                                   placeholder="Serial number"
                                                   value={values.serialNumber}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12 p-0 mt-3 hv-center">
                                            <QRCode value={"Name: " + values.name + " | SerialNR: " + values.serialNumber + " | Control/Revision Date: " + Moment(values.endDate.date).format('DD.MM.YYYY') + " | Notes: " + values.note} />
                                        </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 p-0">
                                        <label htmlFor="inputFile" className={ errors.image ? 'text-danger' : '' }>Image</label>
                                        <input id="image" name="image" type="file" className="form-control" onChange={(event) => {
                                            setFieldValue("image", event.currentTarget.files[0]);
                                            setThumb(URL.createObjectURL(event.currentTarget.files[0]))
                                        }} />
                                        <span className="text-danger">{errors.image ? errors.image : null}</span>
                                    </div>
                                    <div className="col-md-12 p-0 mt-3 hv-center">
                                        <img className="img-fluid" src={ thumb } alt="thumb" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="inputDescription">Description</label>
                                <textarea type="description"
                                          name="description"
                                          className="form-control"
                                          id="description"
                                          placeholder="Task description"
                                          value={values.description}
                                          rows="5"
                                          onChange={handleChange}
                                />
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="inputNote">Notes</label>
                                <input type="text"
                                          name="note"
                                          className="form-control"
                                          id="note"
                                          placeholder="Task notes"
                                          value={values.note}
                                          onChange={handleChange}
                                />
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemFirstName" className={ errors.remFirstName ? 'text-danger' : '' }>1st reminder - Message</label>
                                    <input type="text"
                                           name="remFirstName"
                                           className={ errors.remFirstName ? 'form-control is-invalid' : 'form-control' }
                                           id="remFirstName"
                                           aria-describedby="remFirstNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remFirstName ? values.remFirstName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remFirstName ? errors.remFirstName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remFirstNameDate" className={ errors.remFirstDate ? 'text-danger' : '' }>1st reminder - Date</label>
                                    <DatePicker
                                           selected={values.remFirstDate}
                                           name="remFirstDate"
                                           className={ errors.remFirstDate ? 'form-control is-invalid' : 'form-control' }
                                           id="remFirstDate"
                                           value={values.remFirstDate}
                                           onChange={date => setFieldValue('remFirstDate', date ? date : "")}
                                           showWeekNumbers
                                           isClearable
                                           placeholderText="dd.mm.yyyy"
                                           dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remFirstDate ? errors.remFirstDate : null}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemSecondName" className={ errors.remSecondName ? 'text-danger' : '' }>2nd reminder - Message</label>
                                    <input type="text"
                                           name="remSecondName"
                                           className={ errors.remSecondName ? 'form-control is-invalid' : 'form-control' }
                                           id="remSecondName"
                                           aria-describedby="remSecondNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remSecondName ? values.remSecondName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remSecondName ? errors.remSecondName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remSecondNameDate" className={ errors.remSecondDate ? 'text-danger' : '' }>2nd reminder - Date</label>
                                    <DatePicker
                                           selected={values.remSecondDate}
                                           name="remSecondDate"
                                           className={ errors.remSecondDate ? 'form-control is-invalid' : 'form-control' }
                                           id="remSecondDate"
                                           value={values.remSecondDate}
                                           onChange={date => setFieldValue('remSecondDate', date ? date : "")}
                                           showWeekNumbers
                                           isClearable
                                           placeholderText="dd.mm.yyyy"
                                           dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remSecondDate ? errors.remSecondDate : null}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemThirdName" className={ errors.remThirdName ? 'text-danger' : '' }>3rd reminder - Message</label>
                                    <input type="text"
                                           name="remThirdName"
                                           className={ errors.remThirdName ? 'form-control is-invalid' : 'form-control' }
                                           id="remThirdName"
                                           aria-describedby="remThirdNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remThirdName ? values.remThirdName : ''}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remThirdName ? errors.remThirdName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remThirdNameDate" className={ errors.remThirdDate ? 'text-danger' : '' }>3rd reminder - Date</label>
                                    <DatePicker
                                           selected={values.remThirdDate}
                                           name="remThirdDate"
                                           className={ errors.remThirdDate ? 'form-control is-invalid' : 'form-control' }
                                           id="remThirdDate"
                                           value={values.remThirdDate}
                                           onChange={date => setFieldValue('remThirdDate', date ? date : "")}
                                           showWeekNumbers
                                           isClearable
                                           placeholderText="dd.mm.yyyy"
                                           dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remThirdDate ? errors.remThirdDate : null}</span>
                                </div>
                            </div>
                            <div className="">
                                <Link
                                    type="cancel"
                                    className="btn btn-light col-md-3 mt-1 float-left"
                                    to="/tasks"
                                >Back</Link>
                                <button
                                    type="submit"
                                    className="btn btn-info col-md-3 mt-1 float-right"
                                >Submit</button>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(TaskForm);