import React from 'react';
import { Link, withRouter } from "react-router-dom";
import Moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

function GroupShow(props) {

    const record = props.record;

    return(
        <>
        <div className="col pl-0 pr-0 pb-3">
            <span className="font-weight-bold">Name: </span>
            <span>{record.name}</span>
        </div>
        <div className="col pl-0 pr-0 pb-3">
            <span className="font-weight-bold">Description: </span>
            <span>{record.description}</span>
        </div>
        <div className="col pl-0 pr-0 pb-3">
            <span className="font-weight-bold">
                Direct reminders:
            </span>
            <span>
                <hr/>
                {
                    record.reminders.length > 0 ?
                        record.reminders.map((task, index) =>
                            <div key={index}>
                                <div className="col font-italic">
                                    { Moment(task.reminderDate.date).format('DD.MM.YYYY') }
                                    { task.sentDate ? <FontAwesomeIcon icon={faCalendarCheck} className="text-success ml-3" /> : '' }
                                    { task.sentDate ? <span className="text-success"> { Moment(task.sentDate.date).format('DD.MM.YYYY') } </span> : '' }
                                </div>
                                <div className="col">{ task.message }</div>
                                <hr/>
                            </div>
                        )
                    :
                        <>
                        <div className="col text-muted font-italic mt-2">No direct reminders added</div>
                        <hr/>
                        </>
                }
            </span>
        </div>
        <div className="col pl-0 pr-0 pb-3">
            <span className="font-weight-bold">
                Tasks:
                <Link className="badge badge-info ml-2" title="Add task" to="/task/new">
                    <FontAwesomeIcon icon={faPlus} />
                </Link>
            </span>
            <span>
                <hr/>
                {
                    record.tasks.length > 0 ?
                        record.tasks.map((task, index) =>
                            <div key={index}>
                                <div className="col font-italic">
                                    <Link to={'/task/edit/' + task.id} className="mr-2" title="Edit task"><FontAwesomeIcon icon={faPen} /></Link>
                                    { task.name }
                                </div>
                                <div className="col">{ Moment(task.startDate.date).format('DD.MM.YYYY') } / { Moment(task.endDate.date).format('DD.MM.YYYY') }</div>
                                <div className="col">{ task.description }</div>
                                <hr/>
                            </div>
                        )
                    :
                        <>
                        <div className="col text-muted font-italic mt-2">No tasks added</div>
                        <hr/>
                        </>
                }
            </span>
        </div>
        </>
    )
}

export default withRouter(GroupShow);