import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

export const accountService = {
    update,
    getSettings,
    getUsers
};

function update(data) {
    const requestData = {
        id: data.id,
        value: data.value,
    };
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.patch(config.apiUrl + '/account/setting/' + requestData.id, requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function getSettings(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/account/settings', requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function getUsers(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/account/users', requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}