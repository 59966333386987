import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import './User.css';
import { userService } from '../../services/UserService';

const userValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    role: Yup.string().required("Name is required")
});
const apiErrors = [];

function UserForm(props) {

    const [user, setUser] = useState({
        isActive: "",
        email: "",
        firstName: "",
        lastName: ""
    });
    const roles = [
        {label: "Team Manager", value: 2},
        {label: "User", value: 3}
    ];

    const redirectToList = (message) => {
        props.history.push('/users');
        //window.location.reload(true);
    }
    // eslint-disable-next-line
    const redirectToError = (message) => {
        props.history.push('/error/' + message);
        //window.location.reload(true);
    }

    useEffect(() => {
        async function getUser() {
            const responseUserData = await userService.get({id: props.match.params.id}).then(respData => respData);

            if (responseUserData.status !== 200) {
                props.history.push('/users')
            } else {
                const responseUser = responseUserData.data;
                const userObject = {
                    isActive: responseUser.isActive,
                    role: responseUser.role,
                    email: responseUser.email,
                    firstName: responseUser.firstName,
                    lastName: responseUser.lastName
                };
                setUser(userObject)
            }
        }
        getUser();
        // eslint-disable-next-line
    }, []);

    return(
        <Formik
            initialValues={ user }
            enableReinitialize={true}
            validationSchema={ userValidationSchema }
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    id: props.match.params.id,
                    email: values.email,
                    role: values.role,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    // eslint-disable-next-line
                    isActive: values.isActive == 1 ? 1 : 0
                }
                userService.update(requestData)
                    .then(function (response) {
                        if(response.status === 200){
                            redirectToList()
                        } else if (response.errorMessage){
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Please enter valid information');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card user-invite-card col-lg-6 hv-center pt-4 pb-4">
                        <h2 className="mb-5">Update user: {values.firstName} {values.lastName}</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group text-left">
                                <label htmlFor="inputEmail">Email</label>
                                <div className="font-weight-bold">{values.email}</div>
                                <span className="text-danger">{errors.email ? errors.email : null}</span>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col-md-6">
                                    <div>&nbsp;</div>

                                    <label className="switch mr-2">
                                        <input
                                            type="checkbox"
                                            // eslint-disable-next-line
                                            checked={ values.isActive == 1 ? true : false }
                                            className="success"
                                            id="isActive"
                                            onChange={handleChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div htmlFor="inputIsActive" className={ errors.isActive ? 'mt-2 text-danger' : 'mt-2' }>Active</div>
                                    <span className="text-danger">{errors.isActive ? errors.isActive : null}</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="selectRole" className={ errors.role ? 'text-danger' : '' }>Role</label>
                                    <select
                                        name="role"
                                        className={ errors.role ? 'form-control is-invalid' : 'form-control' }
                                        id="role"
                                        aria-describedby="roleHelp"
                                        placeholder="Select role"
                                        value={values.role}
                                        onChange={handleChange}
                                    >
                                        <option key="" value="">-- Select role --</option>
                                        {roles.map(role => (
                                            <option key={role.value} value={role.value}>
                                                {role.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.role ? errors.role : null}</span>
                                </div>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col-md-6">
                                    <label htmlFor="inputFirstName" className={ errors.firstName ? 'text-danger' : '' }>First name</label>
                                    <input type="firstName"
                                           name="firstName"
                                           className={ errors.firstName ? 'form-control is-invalid' : 'form-control' }
                                           id="firstName"
                                           aria-describedby="firstNameHelp"
                                           placeholder="Enter first name"
                                           value={values.firstName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.firstName ? errors.firstName : null}</span>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputLastName" className={ errors.lastName ? 'text-danger' : '' }>Last name</label>
                                    <input type="lastName"
                                           name="lastName"
                                           className="form-control"
                                           id="lastName"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Enter last name"
                                           value={values.lastName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.lastName ? errors.lastName : null}</span>
                                </div>
                            </div>
                            <div className="">
                                <Link
                                    type="cancel"
                                    className="btn btn-light col-md-3 mt-1 float-left"
                                    to="/users"
                                >Back</Link>
                                <button
                                    type="submit"
                                    className="btn btn-info col-md-3 mt-1 float-right"
                                >Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(UserForm);