import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import { Formik } from "formik";
import * as Yup from "yup";
import Moment from 'moment'

import './GroupForm.css';
import { groupService } from '../../services/GroupService';

const groupNewValidationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    remFirstDate: Yup.date(),
    remSecondDate: Yup.date(),
    remThirdDate: Yup.date()
});
const apiErrors = [];

function GroupForm(props) {

    const isEditMode = !!props.match.params.id;
    const defaultData = {
        id: "",
        name: "",
        description: "",
        remFirstId: "",
        remFirstName: "",
        remFirstDate: "",
        remSecondId: "",
        remSecondName: "",
        remSecondDate: "",
        remThirdId: "",
        remThirdName: "",
        remThirdDate: ""
    }
    const [group, setGroup] = useState(defaultData);

    useEffect(() => {
        if (isEditMode) {
            async function getGroup() {
                const responseGroupData = await groupService.get({id: props.match.params.id}).then(respData => respData);

                if (responseGroupData.status !== 200) {
                    props.history.push('/groups')
                } else {
                    const responseGroup = responseGroupData.data;
                    const groupObject = {
                        id: responseGroup.id,
                        name: responseGroup.name,
                        description: responseGroup.description,
                        remFirstId: responseGroup.reminders.length >= 1 ? responseGroup.reminders[0].id : "",
                        remFirstName: responseGroup.reminders.length >= 1 ? responseGroup.reminders[0].message : "",
                        remFirstDate: responseGroup.reminders.length >= 1 ? new Date(Moment(responseGroup.reminders[0].reminderDate.date).toLocaleString()) : "",
                        remSecondId: responseGroup.reminders.length >= 2 ? responseGroup.reminders[1].id : "",
                        remSecondName: responseGroup.reminders.length >= 2 ? responseGroup.reminders[1].message : "",
                        remSecondDate: responseGroup.reminders.length >= 2 ? new Date(Moment(responseGroup.reminders[1].reminderDate.date).toLocaleString()) : "",
                        remThirdId: responseGroup.reminders.length >= 3 ? responseGroup.reminders[2].id : "",
                        remThirdName: responseGroup.reminders.length >= 3 ? responseGroup.reminders[2].message : "",
                        remThirdDate: responseGroup.reminders.length >= 3 ? new Date(Moment(responseGroup.reminders[2].reminderDate.date).toLocaleString()) : ""
                    };
                    setGroup(groupObject)
                }
            }
            getGroup();
        }
        // eslint-disable-next-line
    }, []);

    return(
        <Formik
            initialValues={ group }
            enableReinitialize={true}
            validationSchema={groupNewValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    id: values.id,
                    name: values.name,
                    description: values.description,
                    reminders: [
                        {id: values.remFirstId, message: values.remFirstName, reminderDate: values.remFirstDate ? Moment(values.remFirstDate).toLocaleString() : null},
                        {id: values.remSecondId, message: values.remSecondName, reminderDate: values.remSecondDate ? Moment(values.remSecondDate).toLocaleString() : null},
                        {id: values.remThirdId, message: values.remThirdName, reminderDate: values.remThirdDate ? Moment(values.remThirdDate).toLocaleString() : null},
                    ]
                }
                isEditMode ?
                    groupService.update(requestData)
                        .then(function (response) {
                            if (response.status === 200) {
                                props.history.push('/groups');
                            } else if (response.errorMessage) {
                                setFieldError('name', response.errorMessage);
                                apiErrors.push('name');
                            } else {
                                setFieldError('name', 'API - Not valid data');
                                apiErrors.push('name');
                            }
                        })
                :
                    groupService.add(requestData)
                        .then(function (response) {
                            if (response.status === 200) {
                                props.history.push('/groups');
                            } else if (response.errorMessage) {
                                setFieldError('name', response.errorMessage);
                                apiErrors.push('name');
                            } else {
                                setFieldError('name', 'API - Not valid data');
                                apiErrors.push('name');
                            }
                        })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with added data";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card group-new-card col-lg-8 hv-center">
                        <h2 className="mb-5">{isEditMode ? 'Edit: ' + values.name : 'Add New Group'}</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group text-left">
                                <label htmlFor="inputName" className={ errors.name ? 'text-danger' : '' }>Name</label>
                                <input type="text"
                                       name="name"
                                       className={ errors.name ? 'form-control is-invalid' : 'form-control' }
                                       id="name"
                                       aria-describedby="nameHelp"
                                       placeholder="Enter group name"
                                       value={values.name ? values.name : ""}
                                       onChange={handleChange}
                                />
                                <span className="text-danger">{errors.name ? errors.name : null}</span>
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="inputDescription">Description</label>
                                <textarea type="description"
                                          name="description"
                                          className="form-control"
                                          id="description"
                                          placeholder="Group description"
                                          value={values.description}
                                          rows="5"
                                          onChange={handleChange}
                                />
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemFirstName" className={ errors.remFirstName ? 'text-danger' : '' }>1st reminder - Message</label>
                                    <input type="text"
                                           name="remFirstName"
                                           className={ errors.remFirstName ? 'form-control is-invalid' : 'form-control' }
                                           id="remFirstName"
                                           aria-describedby="remFirstNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remFirstName ? values.remFirstName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remFirstName ? errors.remFirstName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remFirstNameDate" className={ errors.remFirstDate ? 'text-danger' : '' }>1st reminder - Date</label>
                                    <DatePicker
                                        selected={values.remFirstDate}
                                        name="remFirstDate"
                                        className={ errors.remFirstDate ? 'form-control is-invalid' : 'form-control' }
                                        id="remFirstDate"
                                        value={values.remFirstDate}
                                        onChange={date => setFieldValue('remFirstDate', date ? date : "")}
                                        showWeekNumbers
                                        isClearable
                                        placeholderText="dd.mm.yyyy"
                                        dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remFirstDate ? errors.remFirstDate : null}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemSecondName" className={ errors.remSecondName ? 'text-danger' : '' }>2nd reminder - Message</label>
                                    <input type="text"
                                           name="remSecondName"
                                           className={ errors.remSecondName ? 'form-control is-invalid' : 'form-control' }
                                           id="remSecondName"
                                           aria-describedby="remSecondNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remSecondName ? values.remSecondName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remSecondName ? errors.remSecondName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remSecondNameDate" className={ errors.remSecondDate ? 'text-danger' : '' }>2nd reminder - Date</label>
                                    <DatePicker
                                        selected={values.remSecondDate}
                                        name="remSecondDate"
                                        className={ errors.remSecondDate ? 'form-control is-invalid' : 'form-control' }
                                        id="remSecondDate"
                                        value={values.remSecondDate}
                                        onChange={date => setFieldValue('remSecondDate', date ? date : "")}
                                        showWeekNumbers
                                        isClearable
                                        placeholderText="dd.mm.yyyy"
                                        dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remSecondDate ? errors.remSecondDate : null}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group form-row text-left">
                                <div className="col-md-9">
                                    <label htmlFor="inputRemThirdName" className={ errors.remThirdName ? 'text-danger' : '' }>3rd reminder - Message</label>
                                    <input type="text"
                                           name="remThirdName"
                                           className={ errors.remThirdName ? 'form-control is-invalid' : 'form-control' }
                                           id="remThirdName"
                                           aria-describedby="remThirdNameHelp"
                                           placeholder="Wished text to be send. Defautl: Reminder - [Your task name]"
                                           value={values.remThirdName ? values.remThirdName : ''}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.remThirdName ? errors.remThirdName : null}</span>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="remThirdNameDate" className={ errors.remThirdDate ? 'text-danger' : '' }>3rd reminder - Date</label>
                                    <DatePicker
                                        selected={values.remThirdDate}
                                        name="remThirdDate"
                                        className={ errors.remThirdDate ? 'form-control is-invalid' : 'form-control' }
                                        id="remThirdDate"
                                        value={values.remThirdDate}
                                        onChange={date => setFieldValue('remThirdDate', date ? date : "")}
                                        showWeekNumbers
                                        isClearable
                                        placeholderText="dd.mm.yyyy"
                                        dateFormat="dd.MM.yyyy"
                                    />
                                    <span className="text-danger">{errors.remThirdDate ? errors.remThirdDate : null}</span>
                                </div>
                            </div>
                            <div className="">
                                <Link
                                    type="cancel"
                                    className="btn btn-light col-md-3 mt-1 float-left"
                                    to="/groups"
                                >Back</Link>
                                <button
                                    type="submit"
                                    className="btn btn-info col-md-3 mt-1 float-right"
                                >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(GroupForm);