import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import ListTemplate from '../../helpers/List/ListTemplate'

import { reminderService } from '../../services/ReminderService';

function ReminderList(props) {

    const urlParams = new URLSearchParams(props.location.search)

    /*
    const acceptedTypes = ['unsent', 'sent']
    const defaultType = 'unsent'
    */
    const type = urlParams.get('sr');
    const storageListKey = 'remList' + (type ? type : 'All');

    const listSettings = {
        listTitle: 'My reminders',
        listId: 'reminders',
        listLinks: {
            list: '/reminders',
            //show: '/reminder/show',
            //new: '/group/new',
            //edit: '/group/edit/',
            delete: '/reminder/del/',
            /*
            customTitleLink: [
                {id: defaultType, name: 'Upcoming', link: '/reminders?sr=' + defaultType},
                {id: acceptedTypes[1], name: 'Sent', link: '/reminders/?sr=' + acceptedTypes[1]},
            ]
            */
        },
        listSearch: {
            searchParams: urlParams,
            searchFields: ['reminder']
        },
        pattern: [
            //{name: 'id'},
            {name: 'reminderDate', isDate: true, class: 'font-weight-bold'},
            {name: 'sentDate', isDate: true, class: 'text-success font-weight-bold'},
            {name: 'message', class: 'font-weight-bold'},
            {name: 'task'},
            {name: 'group'}
        ],
        w3dService: reminderService
    };

    const listHead = [
        //{name: 'ID'},
        {name: 'Reminder date'},
        {name: 'Sent date'},
        {name: 'Message'},
        {name: 'Task'},
        {name: 'Group'},
        {name: 'Actions', class: 'text-right'}
    ];

    const [listBody, setData] = useState([]);

    useEffect(() => {
        if (type === "") {
            setData({status: 400, errorMessage: 'The URL is not correct'})
        } else {
            reminderService.list({type: type, storageKey: storageListKey}).then(response => setData(response))
        }
        // eslint-disable-next-line
    }, []);

    return(
        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={listBody} />
    )
}

export default withRouter(ReminderList);