import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { userService } from '../../services/UserService';

// eslint-disable-next-line
const PrivilegeRoute = ({ component: Component, path: path }) => (
    <Route path={path} render={props => (
        userService.isLogged()
            ? <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            : <Component {...props} />
    )} />
)

export default PrivilegeRoute;