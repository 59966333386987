import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";

import './User.css';
import { userService } from '../../services/UserService';

const userInviteValidationSchema = Yup.object({
    email: Yup.string().email("Not valid Email").required("Email is required")
});
const apiErrors = [];

function UserInviteForm(props) {
    const redirectToUsers = () => {
        props.history.push('/users');
        //window.location.reload(true);
    }

    return(
        <Formik
            initialValues={{ email: "" }}
            validationSchema={userInviteValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    email: values.email
                }
                userService.invite(requestData)
                    .then(function (response) {
                        if (response.status === 200) {
                            redirectToUsers();
                        } else if (response.errorMessage) {
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Not valid credentials');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <>
                    <div className="col-12 mt-3 mb-2 hv-center">
                        <div className="card user-invite-card col-lg-6 hv-center">
                            <h2 className="mb-5">Send invitation</h2>
                            <form className="col-lg-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group text-left">
                                            <input type="text"
                                                   name="email"
                                                   className={ errors.email ? 'form-control is-invalid mt-1' : 'form-control mt-1' }
                                                   id="email"
                                                   aria-describedby="emailHelp"
                                                   placeholder="Enter email"
                                                   value={values.email}
                                                   onChange={handleChange}
                                            />
                                            <span className="text-danger">{errors.email ? errors.email : null}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-info col-md-12"
                                            >Send invitation</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="mt-3 text-center">
                        <Link className="loginText mt-2" title="Users" to="/users">Back to User's list</Link>
                    </div>
                </>
            )}
        </Formik>
    )
}

export default withRouter(UserInviteForm);