import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import './User.css';
import { userService } from '../../services/UserService';

const userInviteAcceptValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    password: Yup.string().min(8).required("Password is required"),
    confirmPassword: Yup.string().min(8).required("Confirm is required").oneOf([Yup.ref('password'), null], "Password doesn't match")
});
const apiErrors = [];

function UserInviteAcceptForm(props) {

    const [invitation, setInvitation] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: ""
    });

    const redirectToSuccess = (message) => {
        props.history.push('/success/' + message);
        //window.location.reload(true);
    }
    const redirectToError = (message) => {
        props.history.push('/error/' + message);
        //window.location.reload(true);
    }

    useEffect(() => {
        async function getInvitation() {
            const responseInvitationData = await userService.inviteCheck({invitationKey: props.match.params.hash}).then(respData => respData);

            if (responseInvitationData.status !== 200) {
                redirectToError(responseInvitationData.errorMessage)
            } else {
                const responseInvite = responseInvitationData.data;
                const inviteObject = {
                    email: responseInvite.email,
                    password: "",
                    firstName: "",
                    lastName: ""
                };
                setInvitation(inviteObject)
            }
        }
        getInvitation();
        // eslint-disable-next-line
    }, []);

    return(
        <Formik
            initialValues={ invitation }
            enableReinitialize={true}
            validationSchema={ userInviteAcceptValidationSchema }
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    email: values.email,
                    password: values.password,
                    confirmedPassword: values.confirmPassword,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    invitationHash: props.match.params.hash
                }
                userService.inviteAccept(requestData)
                    .then(function (response) {
                        if(response.status === 200){
                            redirectToSuccess("Acceptance of the invitation was successfully.");
                        } else if (response.errorMessage){
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Please enter valid information');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card user-invite-card col-lg-4 hv-center pt-4 pb-4">
                        <h2 className="mb-5">Accept invitation</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group text-left">
                                <label htmlFor="inputEmail">Email</label>
                                <div className="font-weight-bold">{values.email}</div>
                                <span className="text-danger">{errors.email ? errors.email : null}</span>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col">
                                    <label htmlFor="inputFirstName" className={ errors.firstName ? 'text-danger' : '' }>First name</label>
                                    <input type="firstName"
                                           name="firstName"
                                           className={ errors.firstName ? 'form-control is-invalid' : 'form-control' }
                                           id="firstName"
                                           aria-describedby="firstNameHelp"
                                           placeholder="Enter first name"
                                           value={values.firstName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.firstName ? errors.firstName : null}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputLastName" className={ errors.lastName ? 'text-danger' : '' }>Last name</label>
                                    <input type="lastName"
                                           name="lastName"
                                           className="form-control"
                                           id="lastName"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Enter last name"
                                           value={values.lastName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.lastName ? errors.lastName : null}</span>
                                </div>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col">
                                    <label htmlFor="inputPassword" className={ errors.password ? 'text-danger' : '' }>Password</label>
                                    <input type="password"
                                           name="password"
                                           autoComplete="no"
                                           className={ errors.password ? 'form-control is-invalid' : 'form-control' }
                                           id="password"
                                           placeholder="Password"
                                           value={values.password}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.password ? errors.password : null}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputConffirmPassword" className={ errors.confirmPassword ? 'text-danger' : '' }>Confirm Password</label>
                                    <input type="password"
                                           name="confirmPassword"
                                           autoComplete="no"
                                           className={ errors.confirmPassword ? 'form-control is-invalid' : 'form-control' }
                                           id="confirmPassword"
                                           placeholder="Confirm Password"
                                           value={values.confirmPassword}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.confirmPassword ? errors.confirmPassword : null}</span>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-info col-md-12"
                            >
                                Accept
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(UserInviteAcceptForm);