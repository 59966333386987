import React from 'react';
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import './ContactForm.css';
import { contactService } from '../../services/ContactService';

const contactValidationSchema = Yup.object({
    email: Yup.string().email("Not valid Email").required("Email is required"),
    comment: Yup.string().max(1000).required("Comment is required")
});
const apiErrors = [];

function ContactForm(props) {
    const redirectToSuccess = (message) => {
        props.history.push('/success/' + message);
        //window.location.reload(true);
    }
    return(
        <Formik
            initialValues={{ email: "", subject: "", comment: "", fullName: "" }}
            validationSchema={contactValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    email: values.email,
                    fullName: values.fullName,
                    subject: values.subject,
                    comment: values.comment
                }
                contactService.sendRequest(requestData)
                    .then(function (response) {
                        if (response.status === 200) {
                            redirectToSuccess("Your request was successfully send. Our team will contact you shortly.");
                        } else if (response.errorMessage) {
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Not valid credentials');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card contact-card col-md-9">
                        <div className="row">
                            <div className="col-lg-9 pl-5 pt-5 pb-5 pr-2">
                                <h2 className="mb-5 ml-3">Contact Us</h2>
                                <form className="col-lg-12" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                                    <div className="form-group form-row text-left">
                                        <div className="col-lg-6">
                                            <label htmlFor="inputEmail" className={ errors.email ? 'text-danger' : '' }>Your Email</label>
                                            <input type="email"
                                                   name="email"
                                                   className={ errors.email ? 'form-control is-invalid' : 'form-control' }
                                                   id="email"
                                                   aria-describedby="emailHelp"
                                                   placeholder="Enter email"
                                                   value={values.email}
                                                   onChange={handleChange}
                                            />
                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                            <span className="text-danger">{errors.email ? errors.email : null}</span>
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="inputSubject">Your name</label>
                                            <input type="text"
                                                   name="fullName"
                                                   className="form-control"
                                                   id="fullName"
                                                   placeholder="Enter your name"
                                                   value={values.fullName}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group form-row text-left">
                                        <div className="col-lg-6">
                                            <label htmlFor="inputSubject">Subject</label>
                                            <input type="subject"
                                                   name="subject"
                                                   className="form-control"
                                                   id="subject"
                                                   aria-describedby="subjectHelp"
                                                   placeholder="Enter subject"
                                                   value={values.subject}
                                                   onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group text-left">
                                        <label htmlFor="inputComment" className={ errors.comment ? 'text-danger' : '' }>Your request</label>
                                        <textarea type="comment"
                                                  name="comment"
                                                  className={ errors.comment ? 'form-control is-invalid' : 'form-control' }
                                                  id="comment"
                                                  placeholder="Add your request ..."
                                                  value={values.comment}
                                                  rows="10"
                                                  onChange={handleChange}
                                        />
                                        <span className="text-danger">{errors.comment ? errors.comment : null}</span>
                                    </div>
                                    <div className="form-check">
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-info col-lg-3 mt-1"
                                    >Send request</button>
                                </form>
                            </div>
                            <div className="col-lg-3 pl-4 pt-5 pb-4 pr-5 bg-w3ds">
                                <h3 className="mb-5">W3D Remind me!</h3>
                                <h6 className="font-italic font-weight-normal pb-2">"We love our work and do it with passion"</h6>

                                <h6 className="pt-3 font-weight-normal">Our location</h6>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} size="1x" className="mr-3 text-info" />Sofia, Bulgaria</p>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} size="1x" className="mr-3 text-info" />Frankfurt, Germany</p>

                                <h6 className="pt-3 font-weight-normal">Contact our Sales team</h6>
                                <p><FontAwesomeIcon icon={faPaperPlane} size="1x" className="mr-3 text-info" />sales@w3dsoft.com</p>
                                <h6 className="pt-3 font-weight-normal">Contact our Support team</h6>
                                <p><FontAwesomeIcon icon={faPaperPlane} size="1x" className="mr-3 text-info" />support@w3dsoft.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(ContactForm);