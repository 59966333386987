import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";

import SubscriptionPlan from '../Subscription/SubscriptionPlan';
import AlertComponent from '../../components/AlertComponent/AlertComponent'

import { subscriptionService } from '../../services/SubscriptionService';

function Subscription(props) {

    const acceptedTypes = ['monthly', 'yearly']
    const defaultType = 'monthly'
    const type = !!props.match.params.type && acceptedTypes.includes(props.match.params.type) ? props.match.params.type : defaultType;
    const storageListKey = 'subscrList' + type;

    const [listBody, setData] = useState([]);

    useEffect(() => {
        if (type === "") {
            setData({status: 400, errorMessage: 'The URL is not correct'})
        } else {
            subscriptionService.list({storageKey: storageListKey}).then(response => setData(response))
        }
        // eslint-disable-next-line
    }, []);

    return(
        <div className="mt-2">
            <h2>Subscription Plans
                {/*
                <a className={type === defaultType ? "btn btn-info ml-2 active" : "btn btn-info ml-2"} href="/subscription/monthly">Monthly</a>
                <a className={type !== defaultType ? "btn btn-info ml-2 active" : "btn btn-info ml-2"} href="/subscription/yearly">Yearly</a>
                */}
            </h2>
            <div className="content">
                <div className="text-muted font-italic">
                    Note: If you upgrade your plan, new plan will be pushed after current one is expired and charge will be done when current payment is expired. For more information, please contact our <Link to="/contact">Support</Link>.
                </div>
                {
                    listBody.status !== 200 ?
                        <AlertComponent statusMessage={listBody.errorMessage} status={listBody.status} isError="true" />
                    :
                        <>
                        <SubscriptionPlan listBody={listBody.data.items} listType={type} ownPlan={listBody.data.ownPlan} />
                        <div className="text-muted font-italic">
                            * Additional price for Pushover App
                        </div>

                        <div className="mt-4 mb-2"><hr/></div>

                        <div className="flex">
                            <h2>Your payments</h2>
                            <table className="table table-striped mt-4">
                                <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Plan</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col" className="text-right">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">15.05.2020</th>
                                    <td>Flex (monthly)</td>
                                    <td>9.99 &euro;</td>
                                    <td className="text-right">
                                        <Link to="#"><FontAwesomeIcon icon={faEye} /></Link>
                                        <Link className="ml-2" to="#"><FontAwesomeIcon icon={faDownload} /></Link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        </>
                }
            </div>
        </div>
    )
}

export default withRouter(Subscription);