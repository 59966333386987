import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

function ListHeader(props) {

    const iconsMap = {
        'bell': faBell,
    };

    const thHead = props.cols.map((col, index) =>
        <th scope="col" className={col.class ? col.class : "" } key={index}>{ col.isIcon ? <FontAwesomeIcon icon={iconsMap[col.name]} /> : col.name}</th>
    );

    return (
        <thead>
            <tr id={'th' + props.listId}>
                { thHead}
            </tr>
        </thead>
    );
}

export default ListHeader;