import React, { useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import Moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import './SubscriptionPlan.css';
import AlertComponent from '../../components/AlertComponent/AlertComponent'

import { userService } from '../../services/UserService';

function SubscriptionPlan(props) {

    const list = props.listBody
    const ownPlan = props.ownPlan

    const [ownPlanMessage] = useState(() => {
        if (Date.parse(new Date(Moment(ownPlan.paidUntil.date).format('DD.MM.YYYY')).toString())/1000 < Date.parse(new Date().toString())/1000) {
            return {class: 'alert alert-warning', message: 'Your payment expired on: '}
        } else {
            return {class: 'alert alert-success', message: 'Next payment on: '}
        }
    })
    const [errorMessage, setErrorMessage] = useState("")

    const upgradePlan = async (plan) => {
        // ajax call
        const subscResponse = await userService.updateSubscriptionPlan({planId: plan})

        if (subscResponse.status !== 200) {
            setErrorMessage(subscResponse)
        } else {
            window.location.reload(true);
        }
    }

    const recordRow = list.map((item, index) =>
        item.id >= ownPlan.plan &&
            <div className={item.paidUntil ? 'card bg-own-plan' : 'card'} key={ index }>
                {/* <img className="card-img-top" src="#" alt="Card image cap" /> */}
                <div className="card-body">
                    { item.paidUntil &&
                    <div className={ ownPlanMessage.class + " font-italic mb-3" }>{ownPlanMessage.message} { Moment(ownPlan.paidUntil.date).format('MMM DD, YYYY') }</div>
                    }
                    <h3 className="card-title">{ item.name } {item.paidUntil ? '' : <Link className="btn btn-info text-white float-right" to="#" onClick={() => upgradePlan(item.id)}>Upgrade</Link>}</h3>
                    <div className="row col">
                        <div className="card-text">
                            <ul className="list-group">
                                {
                                    JSON.parse(item.description).map((checkItem, key) => (
                                            <li className="list-group-item border-0 pl-1" key={ key }>
                                                <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                                { checkItem.checkbox }
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-bold mb-0">{ item.amount / 100 } &euro;
                        { item.freePeriodValue > 0 && item.id !== 1 &&
                        <span className="ml-3 text-muted"><s>{ Math.round((item.amount / 10) * 12) / 100 }  &euro;</s></span>
                        }
                    </h2>
                        <span className="small">{ item.periodType === 20 ? 'monthly' : 'yearly' }</span>
                </div>
            </div>
    )

    return(
        <div id="#subPlans" className="mt-2">
            <AlertComponent statusMessage={errorMessage.errorMessage} status={errorMessage.status} isError="true" />
            <div className="card-deck">
                { recordRow }
                <div className="card">
                    {/* <img className="card-img-top" src="#" alt="Card image cap" /> */}
                    <div className="card-body">
                        <h3 className="card-title">Premium</h3>
                        <div className="card-text">
                            <ul className="list-group">
                                <li className="list-group-item border-0 pl-1">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                    Unlimited users
                                </li>
                                <li className="list-group-item border-0 pl-1">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                    Unlimited groups
                                </li>
                                <li className="list-group-item border-0 pl-1">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                    Unlimited tasks per group
                                </li>
                                <li className="list-group-item border-0 pl-1">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                    Push notification per Email / Pushover *
                                </li>
                                <li className="list-group-item border-0 pl-1">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-info" />
                                    Montly / Weekly / Yearly report per Email
                                </li>
                            </ul>
                        </div>
                        <div className="row mt-5">
                            <div className="col">
                                <Link className="btn btn-info col" to="/contact">Contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SubscriptionPlan);