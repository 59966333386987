import React from 'react';
import { Link } from "react-router-dom";

import { userService } from '../../services/UserService';

function SuccessPage(props) {

    return(
        <div className="col-12 mt-3 mb-2 hv-center">
            <div className="card col-md-8 alert alert-success p-4">
                <h5 className="font-weight-normal">{ props.match.params.successMessage }</h5>
                <br/>
                {
                    userService.isLogged() ?
                        <Link to="/dashboard">Go to dashboard</Link>
                    :
                        <Link to="/login">Go to login</Link>
                }
            </div>
        </div>
    )
}

export default SuccessPage;