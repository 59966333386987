import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import ListTemplate from '../../helpers/List/ListTemplate'
//import UserShow from './UserShow'

import { accountService } from '../../services/AccountService';
import { userService } from '../../services/UserService';

function UserList(props) {

    const listSettings = {
        listTitle: 'Users',
        listId: 'users',
        listLinks: {
            list: '/users',
            new: '/user/new',
            //show: '/group/show/',
            edit: '/user/edit/',
            //delete: '/user/del/'
        },
        pattern: [
            //{name: 'id'},
            {name: 'isActive', isBool: true},
            {name: 'fullName', class: 'font-weight-bold'},
            {name: 'email'},
            {name: 'roleName'},
            {name: 'createDateTime', isDate: true, class: 'font-weight-bold'},
            {name: 'lastLoginDateTime', isDate: true, class: 'font-weight-bold'},
            {name: 'status'},
        ],
        /*
        templates: {
            show: GroupShow
        },
         */
        w3dService: userService
    };

    const listHead = [
        //{name: 'ID'},
        {name: 'Active'},
        {name: 'Name'},
        {name: 'Email'},
        {name: 'Role'},
        {name: 'Created'},
        {name: 'Last login'},
        {name: 'Status'},
        {name: 'Actions', class: 'text-right'}
    ];

    const [listBody, setData] = useState([]);

    useEffect(() => {
        accountService.getUsers({storageKey: 'userList'}).then(response => setData(response))
    }, []);

    return(
        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={listBody} />
    )
}

export default withRouter(UserList);