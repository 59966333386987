import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

import { taskService } from '../services/TaskService';
import { groupService } from '../services/GroupService';

const storageObjectLiveTime = 0.25; // in hours -> 15 minutes
const remStorageKeyDefault = 'remList';
const remStorageKeyLiveTime = 'remLiveTime';
const remStorageKeys = ['remListsent', 'remListunsent', 'remListLast10Unsent', 'remListLast10Sent', 'remListAll', remStorageKeyDefault, remStorageKeyLiveTime];

export const reminderService = {
    del,
    list,
    clearStorage
};

function clearStorage(keysForDelete) {
    if (keysForDelete.length > 0) {
        keysForDelete.forEach(k => localStorage.removeItem(k))
    } else {
        remStorageKeys.forEach(k => localStorage.removeItem(k))
    }
}

function del(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(remStorageKeys)
    taskService.clearStorage([])
    groupService.clearStorage([])

    return axios.delete(config.apiUrl + '/reminder/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };
    const linkParamsLimit = data !== undefined && data.limit !== undefined ? 'limit=' + data.limit + '&' : '';
    const linkParams = linkParamsLimit + ((data !== undefined && data.type !== undefined && data.type !== "" && data.type != null) ? 'is=' + (data.type === 'sent' ? 1 : 0) + '&' : '');

    const localStorageKey = (data.storageKey !== undefined && data.storageKey !== "") ? data.storageKey : remStorageKeyDefault;

    //clean storage every 15 min
    if (localStorage.getItem(remStorageKeyLiveTime) !== null) {
        if (new Date().getTime() - localStorage.getItem(remStorageKeyLiveTime) > storageObjectLiveTime * 60 * 60 * 1000 ) {
            clearStorage(remStorageKeys)
        }
    }

    if (localStorage.getItem(localStorageKey) !== null) {

        //alert(1)

        return new Promise(function(resolve, reject) {
            resolve(JSON.parse(localStorage.getItem(localStorageKey)));
        });
    } else {
        return axios.get(config.apiUrl + '/reminders?' + linkParams, requestOptions)
            .then(handleResponse)
            .then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    localStorage.setItem(localStorageKey, JSON.stringify(response))
                    localStorage.setItem(remStorageKeyLiveTime, new Date().getTime())
                }
                return response
            })
            .catch(handleError);
    }
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}