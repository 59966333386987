import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCogs } from "@fortawesome/free-solid-svg-icons";

import { userService } from '../../services/UserService';
import { paymentService } from '../../services/PaymentService';

import './Profile.css'
import '../Subscription/SubscriptionPlan.css'

function Profile(props) {

    const [user, setUser] = useState({})
    const [paymentInfo, setPaymentInfo] = useState({})

    useEffect(() => {
        async function getUserInfo() {
            await userService.info({}).then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    setUser(response.data)
                }
            });
        }
        getUserInfo();
    }, [])

    useEffect(() => {
        async function getPaymentInfo() {
            await paymentService.get({}).then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    setPaymentInfo(response.data)
                }
            });
        }
        getPaymentInfo();
    }, [])

    return(
        <div className="mt-2">
            <h2>User Profile</h2>
            <div className="content">
                <div className="card-columns">
                    <div className="card card-profile-plan p-4 bg-own-plan">
                        <h3 className="card-title">Plan: { user.subscriptionPlan }</h3>
                        <div className="card-text">
                            {
                                user.paidUntil &&
                                    (
                                        Date.parse(new Date(Moment(user.paidUntil.date).format('DD.MM.YYYY')).toString())/1000 < Date.parse(new Date().toString())/1000 ?
                                            <div className="alert alert-warning font-italic mb-3">Your payment expired on: { Moment(user.paidUntil.date).format('MMM DD, YYYY') }</div>
                                        :
                                            <div className="alert alert-success font-italic mb-3">Next payment on: { Moment(user.paidUntil.date).format('MMM DD, YYYY') }</div>
                                    )
                            }
                            <Link to="/subscription">Check your subscriptions</Link>
                        </div>
                    </div>
                    <div className="card p-4 bg-own-plan">
                        <h3 className="card-title">Payment info <Link to="#" className="small ml-2"><FontAwesomeIcon icon={faPen} size="1x" /></Link></h3>
                        <div className="card-text">
                            <h5 className="font-weight-normal mb-0">{ paymentInfo.firstName } { paymentInfo.lastName }</h5>
                            <br/><span>Credit card: { paymentInfo.number }</span>
                            <br/><span>Expires: { paymentInfo.expMonth }/{ paymentInfo.expYear }</span>
                        </div>
                    </div>
                    <div className="card p-4 mb-3">
                        <h3 className="card-title">Personal Info
                            <Link to="#" className="small ml-2"><FontAwesomeIcon icon={faPen} size="1x" /></Link>
                            <Link to="/asettings" className="small ml-2"><FontAwesomeIcon icon={faCogs} size="1x" /></Link>
                        </h3>
                        <div className="card-text">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item pl-0">
                                    <label className="font-weight-bold ">Account</label>: { user.account }
                                </li>
                                <li className="list-group-item pl-0">
                                    <label className="font-weight-bold">First name</label>: { user.firstName }
                                </li>
                                <li className="list-group-item pl-0">
                                    <label className="font-weight-bold">Last name</label>: { user.lastName }
                                </li>
                                <li className="list-group-item pl-0">
                                    <label className="font-weight-bold">Email</label>: { user.email }
                                </li>
                                <li className="list-group-item pl-0">
                                    <label className="font-weight-bold">Created</label>: { user.createDateTime && Moment(user.createDateTime.date).format('MMM DD, YYYY') }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;