import React  from 'react'
import { Route, Redirect } from 'react-router-dom'

import { userService } from '../../services/UserService';

// eslint-disable-next-line
const PrivateRoute = ({ component: Component, path: path }) => (
    <Route path={path} render={props => (
        userService.isLogged()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)

export default PrivateRoute;