import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader, authHeaderFile } from '../helpers/AuthHeader';

import { taskService } from '../services/TaskService';

export const fileService = {
    upload,
    get,
    list
};

function upload(data) {
    let formData = new FormData();
    formData.append('file', data.image);
    formData.append('name', data.image.name);
    formData.append('task', data.task);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/file', formData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/file' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };
    const linkParams = data !== undefined && data.linkParams !== undefined ? '?' + data.linkParams : '';

    return axios.get(config.apiUrl + '/files' + linkParams, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {

        taskService.clearStorage([])

        return {
            'status': response.data.status,
        };
    }
    else if (response.data.errorMessage) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}