import React from 'react';
import { withRouter, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faLayerGroup, faTasks, faBell, faUser, faUserEdit, faUsers, faCogs, faMoneyBill, faUserSecret, faLockOpen } from "@fortawesome/free-solid-svg-icons";

import { userService } from '../../services/UserService';

function Header(props) {
    /*
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const title = capitalize(props.location.pathname.substring(1,props.location.pathname.length))
    */

    const redirectToLogin = (e) => {
        userService.logout(true);
        props.history.push('/login');
        window.location.reload(true);
    }

    const isLogged = userService.isLogged();

    return (
        <div className="row ml-0 mr-0">
            <div className="col-md-12 pl-0 pr-0">
                <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
                    <div className="navbar-header">
                        {/*
                        <Link className="navbar-brand float-left" to="/"><img src="../images/logo.png" width="90" alt="W3DSoft Ltd" /></Link>
                        */}
                        <Link className="navbar-brand hv-center float-left" to="/"><h3 className="text-w3ds">Remind me</h3></Link>
                    </div>
                    <div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            {/*
                            <li className="nav-item active">
                                <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                            </li>
                            */}
                            { !isLogged &&
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/product" title="Product">PRODUCT</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about" title="About us">ABOUT US</Link>
                                    </li>
                                    {/*
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/pricing" title="Pricing">PRICING</Link>
                                    </li>
                                    */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/register" title="Register">REGISTER</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link ml-1" to="/login" title="Login">LOGIN</Link>
                                    </li>
                                </>
                            }

                            { isLogged &&
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard"><FontAwesomeIcon icon={faChartBar} size="2x" /></Link>
                            </li>
                            }
                            { isLogged &&
                            <li className="nav-item">
                                <Link className="nav-link ml-1" to="/groups"><FontAwesomeIcon icon={faLayerGroup} size="2x" /></Link>
                            </li>
                            }
                            { isLogged &&
                            <li className="nav-item">
                                <Link className="nav-link ml-1" to="/tasks"><FontAwesomeIcon icon={faTasks} size="2x" /></Link>
                            </li>
                            }
                            { isLogged &&
                            <li className="nav-item">
                                <Link className="nav-link ml-1" to="/reminders"><FontAwesomeIcon icon={faBell} size="2x" /></Link>
                            </li>
                            }
                            { isLogged &&
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle ml-1" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <FontAwesomeIcon icon={faUser} size="2x" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/profile"><FontAwesomeIcon icon={faUserEdit} className="mr-3" />Profile</Link>
                                    <Link className="dropdown-item" to="/users"><FontAwesomeIcon icon={faUsers} className="mr-3" />Users</Link>
                                    <Link className="dropdown-item" to="/asettings"><FontAwesomeIcon icon={faCogs} className="mr-3" />Settings</Link>
                                    <Link className="dropdown-item" to="/subscription"><FontAwesomeIcon icon={faMoneyBill} className="mr-3" />Subscriptions</Link>
                                    <Link className="dropdown-item" to="/upc"><FontAwesomeIcon icon={faUserSecret} className="mr-3" />Update password</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" to="#" onClick={e => redirectToLogin(e)}><FontAwesomeIcon icon={faLockOpen} className="mr-3" />Logout</Link>
                                </div>
                            </li>
                            }
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}
export default withRouter(Header);