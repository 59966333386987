import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

import './Footer.css';

function Footer() {
    return(
        <div className="row ml-0 mr-0">
            <div className="col-md-12 pl-0 pr-0  bg-dark">
                <nav className="navbar navbar-expand-lg col-md-12 pl-0 pr-0 navbar-dark d-flex justify-content-between">
                    <div className="navbar-footer">
                        <span className="navbar-brand">&copy; W3DSoft - All Rights Reserved</span>
                    </div>
                    <div className="navbar-footer">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavFooter" aria-controls="navbarNavFooter" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavFooter">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/product">Product</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">About us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/privacy">Privacy & Policy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contact us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="navbar-footer">
                        <div className="d-flex">
                            <div className="mr-2">
                                <Link to="#" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size="2x" /></Link>
                            </div>
                            <div className="mr-2">
                                <Link to="#" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} size="2x" /></Link>
                            </div>
                            <div className="mr-2">
                                <Link to="#" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} size="2x" /></Link>
                            </div>
                            <div className="mr-2">
                                <Link to="#" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} size="2x" /></Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Footer;