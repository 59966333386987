import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import './UpdatePassword.css';
import { userService } from '../../services/UserService';

const updatePassValidationSchema = Yup.object({
    password: Yup.string().min(8).required("Password is required"),
    newPassword: Yup.string().min(8).required("New password is required"),
    confirmNewPassword: Yup.string().min(8).required("Confirm is required").oneOf([Yup.ref('newPassword'), null], "New password doesn't match")
});
const apiErrors = [];

function UpdatePassword(props) {
    const redirectToDashboard = () => {
        props.history.push('/upc');
        window.location.reload(true);
    }
    return(
        <Formik
            initialValues={{ password: "", newPassword: "" }}
            validationSchema={updatePassValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    password: values.password,
                    newPassword: values.newPassword,
                    confirmNewPassword: values.confirmNewPassword
                }
                userService.updatePassword(requestData)
                    .then(function (response) {
                        if(response.status === 200){
                            redirectToDashboard();
                        } else if (response.errorMessage){
                            setFieldError('password', response.errorMessage);
                            apiErrors.push('password');
                        } else {
                            setFieldError('password', 'API - Please enter valid information');
                            apiErrors.push('password');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.password)) {
                    errors.password = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card upc-card col-lg-4 hv-center">
                        <h2 className="mb-5">Update your password</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group text-left">
                                <label htmlFor="inputPassword" className={ errors.password ? 'text-danger' : '' }>Current Password</label>
                                <input type="password"
                                       name="password"
                                       className={ errors.password ? 'form-control is-invalid' : 'form-control' }
                                       id="password"
                                       aria-describedby="passwordHelp"
                                       placeholder="Enter current password"
                                       value={values.password}
                                       onChange={handleChange}
                                />
                                <span className="text-danger">{errors.password ? errors.password : null}</span>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col">
                                    <label htmlFor="inputNewPassword" className={ errors.newPassword ? 'text-danger' : '' }>New Password</label>
                                    <input type="password"
                                           name="newPassword"
                                           className={ errors.newPassword ? 'form-control is-invalid' : 'form-control' }
                                           id="newPassword"
                                           placeholder="New password"
                                           value={values.newPassword}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.newPassword ? errors.newPassword : null}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputConfirmNewPassword" className={ errors.confirmNewPassword ? 'text-danger' : '' }>Confirm New Password</label>
                                    <input type="password"
                                           name="confirmNewPassword"
                                           className={ errors.confirmNewPassword ? 'form-control is-invalid' : 'form-control' }
                                           id="confirmNewPassword"
                                           placeholder="Confirm New Password"
                                           value={values.confirmNewPassword}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.confirmNewPassword ? errors.confirmNewPassword : null}</span>
                                </div>
                            </div>
                            <div className="">
                                <Link
                                    type="cancel"
                                    className="btn btn-light col-md-3 mt-1 float-left"
                                    to="/profile"
                                >Profile</Link>
                                <button
                                    type="submit"
                                    className="btn btn-info col-md-3 mt-1 float-right"
                                >Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(UpdatePassword);