import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faFileContract, faTasks} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

function Product(props) {
    return(
        <div className="mt-2">
            <div className="container">
                <h2 className="text-center">How our product can help you</h2>
                <div className="row mt-5">
                    <div className="col-md-5">
                        <p><img src="../images/sample.png" alt="" className="col img-responsive" /></p>
                    </div>
                    <div className="col-md-7">
                        <h5>
                            Busy times...
                            <br/>It has never been so hard to track items
                        </h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id felis sit amet est condimentum bibendum non a lacus.
                            Curabitur sit amet leo mauris. Maecenas auctor augue nec velit molestie hendrerit. Nulla eleifend augue eu maximus eleifend.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                            Nullam ligula neque, gravida eu quam sit amet, fermentum semper est.
                        </p>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-7">
                        <h5>
                            ...and it never has been to easy to track items with our solution
                        </h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id felis sit amet est condimentum bibendum non a lacus.
                            Curabitur sit amet leo mauris. Maecenas auctor augue nec velit molestie hendrerit. Nulla eleifend augue eu maximus eleifend.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                            Nullam ligula neque, gravida eu quam sit amet, fermentum semper est.
                        </p>
                    </div>
                    <div className="col-md-5">
                        <p className="text-right"><img src="../images/sample.png" alt="" className="col img-responsive" /></p>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-5">
                        <p><img src="../images/sample.png" alt="" className="col img-responsive" /></p>
                    </div>
                    <div className="col-md-7">
                        <h5>
                            Keep your focus on what is important
                        </h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id felis sit amet est condimentum bibendum non a lacus.
                            Curabitur sit amet leo mauris. Maecenas auctor augue nec velit molestie hendrerit. Nulla eleifend augue eu maximus eleifend.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                            Nullam ligula neque, gravida eu quam sit amet, fermentum semper est.
                        </p>
                    </div>
                </div>

                <div className="row mt-5 text-center">
                    <div className="col-md-12 mb-4">
                        <h3>Try it in 3 Simple Steps</h3>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h4 className="hv-center font-weight-normal">
                            <FontAwesomeIcon className="mr-3 text-primary" icon={faFileContract} size="2x" />
                            Sign Up
                        </h4>
                        <span className="font-italic">Sign up for a Remind me! account.</span>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h4 className="hv-center font-weight-normal">
                            <FontAwesomeIcon className="mr-3 text-info" icon={faTasks} size="2x" />
                            Add Tasks
                        </h4>
                        <span className="font-italic">Categorize your reminders in groups and tasks.</span>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h4 className="hv-center font-weight-normal">
                            <FontAwesomeIcon className="mr-3 text-warning" icon={faBell} size="2x" />
                            Setup Reminders
                        </h4>
                        <span className="font-italic">Manage your reminders attached to different tasks.</span>
                    </div>
                </div>

                <div className="card p-3 mt-4 mb-4">
                    <div className="card-body">
                        <div className="row justify-content-between">
                            <div className="col-sm-12 col-lg-8 align-self-center">
                                <h4 className="font-weight-normal text-center">Get Started With Your Free "Reminder me" Account Now!</h4>
                            </div>
                            <div className="col-sm-12 col-lg-4 text-center">
                                <Link className="btn btn-info" to="/register">Register and try it for free!</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Product;