import { userService } from '../services/UserService';
import { config } from '../constants/ApiConstants';

export function authHeader() {
    if (userService.isLogged()) {
        let user = JSON.parse(localStorage.getItem('user'));
        return {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.token.accessToken};
    } else {
        return {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + config.generalToken};
    }
}

export function authHeaderFile() {
    if (userService.isLogged()) {
        let user = JSON.parse(localStorage.getItem('user'));
        return {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + user.token.accessToken};
    }
}