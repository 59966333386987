import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

const storageObjectLiveTime = 2; // in hours -> 2 hours
const subscrStorageKeyDefault = 'subscrList';
const subscrStorageKeyLiveTime = 'subscrLiveTime';
const subscrStorageKeys = ['subscrListmonthly', 'subscrListyearly', subscrStorageKeyDefault, subscrStorageKeyLiveTime];

export const subscriptionService = {
    list,
    clearStorage
};

function clearStorage(keysForDelete) {
    if (keysForDelete.length > 0) {
        keysForDelete.forEach(k => localStorage.removeItem(k))
    } else {
        subscrStorageKeys.forEach(k => localStorage.removeItem(k))
    }
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    const localStorageKey = (data.storageKey !== 'undefined' && data.storageKey !== "") ? data.storageKey : subscrStorageKeyDefault;

    //clean storage every 2h
    if (localStorage.getItem(subscrStorageKeyLiveTime) !== null) {
        if (new Date().getTime() - localStorage.getItem(subscrStorageKeyLiveTime) > storageObjectLiveTime * 60 * 60 * 1000 ) {
            clearStorage(subscrStorageKeys)
        }
    }

    //clearStorage(subscrStorageKeys)

    if (localStorage.getItem(localStorageKey) !== null) {

        //alert(1)

        return new Promise(function(resolve, reject) {
            resolve(JSON.parse(localStorage.getItem(localStorageKey)));
        });
    } else {
        return axios.get(config.apiUrl + '/subplans', requestOptions)
            .then(handleResponse)
            .then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    localStorage.setItem(localStorageKey, JSON.stringify(response))
                    localStorage.setItem(subscrStorageKeyLiveTime, new Date().getTime())
                }
                return response
            })
            .catch(handleError);
    }
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}