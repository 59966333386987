import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

import AlertComponent from '../../components/AlertComponent/AlertComponent'

import { accountService } from '../../services/AccountService'

import './AccountSettings.css'

function AccountSettings(props) {

    const [settings, setSettings] = useState([])
    const [error, setError] = useState("")
    const [errorStatus, setErrorStatus] = useState(400)

    useEffect(() => {
        async function getSettings() {
            const response = await accountService.getSettings({});
            // eslint-disable-next-line
            if (response.status == 200) {
                setSettings(response.data.items.map(item => (item)));
            } else if (response.errorMessage) {
                setError(response.errorMessage);
                setErrorStatus(response.status);
            } else {
                setError('API - Not valid data');
            }
        }
        getSettings();
    },[]);

    const onFieldChange = (e, item) => {
        item.settingValue = e.target.value
        settings.push(item)
        setSettings(settings)

        //alert(JSON.stringify(item))
        //alert(JSON.stringify(settings))
    }

    const onFieldUpdate = (item) => {

        const newValue = item.type == 'text' ?
            document.getElementById('setting' + item.id).value
        :
            document.getElementById('setting' + item.id).checked

        if (newValue !== '') {
            accountService.update({id: item.id, value: newValue}).then(function (response) {
                if (response.status === 200) {
                    setError(response.errorMessage);
                    window.location.reload(true);
                } else if (response.errorMessage) {
                    setError(response.errorMessage);
                    setErrorStatus(response.status);
                } else {
                    setError('API - Not valid data');
                }
            })
        }

    }

    return(
        <div className="mt-2">
            <h2 className="mb-4">Account Settings</h2>
            <AlertComponent statusMessage={error} status={errorStatus} isError="true" />
            <div className="content">
                <div className="card mb-3">
                    <ul className="list-group list-group-flush">
                        {
                            settings.map((setting, key) => (
                                // eslint-disable-next-line
                                <li className= {setting.type == "text" ? "list-group-item" : "list-group-item border-0"} key={key}>
                                        {
                                            // eslint-disable-next-line
                                            setting.type == "integer" ?
                                                    <>
                                                    <div className="mr-2 w-60px float-left">
                                                        <input type="text" className="form-control text-center" disabled={setting.disabled} value={setting.settingValue} id={setting.id} />
                                                    </div>
                                                    <div className="mt-2 text-muted">{setting.settingName}</div>
                                                    </>
                                                :
                                                    // eslint-disable-next-line
                                                    setting.type == "text" ?
                                                        <>
                                                        <div className="mb-1">
                                                            {setting.settingName}
                                                            <br/><b>Your value:</b> <i>{setting.settingValue === '' ? 'no value yet' : setting.settingValue}</i>
                                                        </div>
                                                        <div className="col-md-3 mr-0 ml-0 pl-0 pr-0 float-left">
                                                            <input type="text" className="form-control text-left font-italic" placeholder="Submit new value" disabled={setting.disabled} id={"setting" + setting.id} />
                                                        </div>
                                                        <div><FontAwesomeIcon icon={faCheck} size="2x" className="ml-3 mt-1 text-w3ds" cursor="pointer" onClick={() => onFieldUpdate(setting)} /></div>
                                                        </>
                                                    :
                                                        // eslint-disable-next-line
                                                        <>
                                                        <label className="switch mr-2">
                                                            <input type="checkbox" checked={ parseInt(setting.settingValue) === 1 ? true : false } disabled={parseInt(setting.disabled) === 1 ? true : false} className={parseInt(setting.disabled) === 1 ? "default" : "success"} id={"setting" + setting.id} onChange={() => onFieldUpdate(setting)} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <div className={parseInt(setting.disabled) === 1 ? "mt-2 text-muted" : "mt-2"}>{setting.settingName}</div>
                                                        </>
                                        }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings;