import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PrivateRoute from './components/Route/PrivateRoute'
import PrivilegeRoute from './components/Route/PrivilegeRoute'
import { config } from './constants/ApiConstants';

import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LeftNav from './components/Navigation/LeftNav';

import Home from './views/Home/Home';
import Product from './views/Product/Product';
import About from './views/About/About';
import Privacy from './views/Privacy/Privacy';

import LoginForm from './views/LoginForm/LoginForm';
//import ForgetPasswordForm from './views/LoginForm/ForgetPasswordForm';
import RegistrationForm from './views/RegistrationForm/RegistrationForm';
import RegistrationConfirm from './views/RegistrationForm/RegistrationConfirm';
import ContactForm from './views/Contact/ContactForm';
import SuccessPage from './views/Page/SuccessPage';
import ErrorPage from './views/Page/ErrorPage';

import Dashboard from './views/Dashboard/Dashboard';
import GroupList from './views/Group/GroupList';
import GroupForm from './views/Group/GroupForm';
import TaskList from './views/Task/TaskList';
import TaskForm from './views/Task/TaskForm';
import ReminderList from './views/Reminders/ReminderList';
import Profile from './views/Profile/Profile';
import UserList from './views/User/UserList';
import UserInviteForm from './views/User/UserInviteForm';
import UserForm from './views/User/UserForm';
import UserInviteAcceptForm from './views/User/UserInviteAcceptForm';
import PaymentInfoForm from './views/Profile/PaymentInfoForm';
import UpdatePassword from './views/Profile/UpdatePassword';
import AccountSettings from './views/AccountSettings/AccountSettings';
import Subscription from './views/Subscription/Subscription';

import { userService } from './services/UserService';

function App() {

    const [title] = useState(null);

    const isLogged = userService.isLogged();

    ReactGA.initialize(config.googleGA, { testMode: true });
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <Router basename="/">
            <div className="w3ds-app">

                <Header title={title} />
                <div className="container-xl">
                    <div className="row ml-0 mr-0">
                        <GoogleReCaptchaProvider
                            reCaptchaKey={config.googleCaptchaKey}
                            language="EN"
                            useRecaptchaNet="true"
                        >
                            { isLogged ? <LeftNav/> : ''}
                            <div className="col text-left">
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/success/:successMessage" component={SuccessPage}/>
                                <Route path="/error/:errorMessage" component={ErrorPage}/>
                                <PrivilegeRoute path="/register" component={RegistrationForm} />
                                <PrivilegeRoute path="/confirm/:confirmationHash" component={RegistrationConfirm} />
                                <PrivilegeRoute exact path="/login" component={LoginForm} />
                                {/*
                                <PrivilegeRoute path="/forgetPassword" component={ForgetPasswordForm} />
                                */}
                                <Route path="/about" component={About} />
                                <Route path="/product" component={Product} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/contact" component={ContactForm}/>
                                <PrivateRoute path="/dashboard" component={Dashboard} />
                                <PrivateRoute path="/groups" component={GroupList} />
                                <PrivateRoute path="/group/new" component={GroupForm} />
                                <PrivateRoute path="/group/edit/:id" component={GroupForm} />
                                <PrivateRoute path="/tasks" component={TaskList} />
                                <PrivateRoute path="/task/new" component={TaskForm} />
                                <PrivateRoute path="/task/edit/:id" component={TaskForm} />
                                <PrivateRoute path="/reminders" component={ReminderList} />
                                <PrivateRoute path="/profile" component={Profile} />
                                <PrivateRoute path="/pinfo" component={PaymentInfoForm} />
                                <PrivateRoute path="/users" component={UserList} />
                                <PrivateRoute path="/user/new" component={UserInviteForm} />
                                <PrivateRoute path="/user/edit/:id" component={UserForm} />
                                <Route path="/join/:hash" component={UserInviteAcceptForm} />
                                <PrivateRoute path="/asettings" component={AccountSettings} />
                                <PrivateRoute path="/upc" component={UpdatePassword} />
                                <PrivateRoute exact path="/subscription/:type" component={Subscription} />
                                <Redirect from="/subscription" to="/subscription/monthly" />
                            </Switch>
                        </div>
                        </GoogleReCaptchaProvider>
                    </div>
                </div>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;