import React from 'react';

import ListItem from './ListItem';

function ListBody(props) {

    return (
        <tbody>
            {
                props.cols.total > 0 ?
                    props.cols.items.map((col, index) =>
                        <ListItem record={col} links={props.links} pattern={props.settings.pattern} w3dService={props.settings.w3dService} templates={props.settings.templates} key={index} />
                    )
                :
                    <tr>
                        <td colSpan={props.settings.pattern.length + 1}>
                            <div className="text-muted font-italic mt-2">No items found</div>
                        </td>
                    </tr>
            }
        </tbody>
    );
}

export default ListBody;