import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

const storageObjectLiveTime = 2; // in hours -> 2 hours
const paymentStorageKeyDefault = 'paymentInfo';
const paymentStorageKeyLiveTime = 'paymentLiveTime';
const paymentStorageKeys = [paymentStorageKeyDefault, paymentStorageKeyLiveTime];

export const paymentService = {
    update,
    get,
    clearStorage
};

function clearStorage(keysForDelete) {
    if (keysForDelete.length > 0) {
        keysForDelete.forEach(k => localStorage.removeItem(k))
    } else {
        paymentStorageKeys.forEach(k => localStorage.removeItem(k))
    }
}

function update(data) {
    const requestData = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        expMonth: data.expMonth,
        expYear: data.expYear,
        number: data.number
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    clearStorage(paymentStorageKeys)

    return axios.post(config.apiUrl + '/payment/card', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}


function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    const localStorageKey = (data.storageKey !== undefined && data.storageKey !== "") ? data.storageKey : paymentStorageKeyDefault;

    //clean storage every 2 hours
    if (localStorage.getItem(paymentStorageKeyLiveTime) !== null) {
        if (new Date().getTime() - localStorage.getItem(paymentStorageKeyLiveTime) > storageObjectLiveTime * 60 * 60 * 1000 ) {
            clearStorage(paymentStorageKeys)
        }
    }

    if (localStorage.getItem(localStorageKey) !== null) {
        return new Promise(function(resolve, reject) {
            resolve(JSON.parse(localStorage.getItem(localStorageKey)));
        });
    } else {
        return axios.get(config.apiUrl + '/payment/card', requestOptions)
            .then(handleResponse)
            .then(response => {
                // eslint-disable-next-line
                if (response.status == 200) {
                    localStorage.setItem(localStorageKey, JSON.stringify(response))
                    localStorage.setItem(paymentStorageKeyLiveTime, new Date().getTime())
                }
                return response
            })
            .catch(handleError);
    }
}

function handleResponse(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {
        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage || response.data.text) {
        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage ? response.data.errorMessage : response.data.text
        };
    }
    else {
        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'There is server error. Please try again later.'
        }
    }
}

function handleError(error) {

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}