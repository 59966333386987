import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import './PaymentInfoForm.css';
import { paymentService } from '../../services/PaymentService';

const paymentInfoValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    expMonth: Yup.string().required('2 digits').matches(/^[0-9]{2}$/, '2 digits'),
    expYear: Yup.string().required('4 digits').matches(/^[0-9]{4}$/, '4 digits'),
    number: Yup.number().typeError('Credit card must be a number').required("Credit card number is required"),
});
const apiErrors = [];

function PaymentInfoForm(props) {

    const defaultData = {
        id: "",
        firstName: "",
        lastName: "",
        expMonth: "",
        expYear: "",
        number: "",
    }
    const [paymentInfo, setPaymentInfo] = useState(defaultData);

    useEffect(() => {
        async function getPaymentInfo() {
            const responseData = await paymentService.get({}).then(respData => respData);

            if (responseData.status !== 200) {
                //props.history.push('/profile')
            } else {
                const responseInfo = responseData.data;
                const paymentInfoObject = {
                    id: responseInfo.id,
                    firstName: responseInfo.firstName,
                    lastName: responseInfo.lastName,
                    expMonth: responseInfo.expMonth,
                    expYear: responseInfo.expYear,
                    number: responseInfo.number,
                };
                setPaymentInfo(paymentInfoObject)
            }
        }
        getPaymentInfo();
    }, []);

    return(
        <Formik
            initialValues={ paymentInfo }
            enableReinitialize={true}
            validationSchema={paymentInfoValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    id: values.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    expMonth: values.expMonth,
                    expYear: values.expYear,
                    number: values.number,
                }
                paymentService.update(requestData)
                    .then(function (response) {
                            if (response.status === 200) {
                                props.history.push('/profile');
                            } else if (response.errorMessage) {
                                setFieldError('number', response.errorMessage);
                                apiErrors.push('number');
                            } else {
                                setFieldError('number', 'API - Not valid data');
                                apiErrors.push('number');
                            }
                        })
            }}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
                <div className="col-12 mt-3 mb-3 hv-center">
                    <div className="card payment-info-card col-lg-6 hv-center pb-3 pt-3">
                        <h2 className="mb-3">Update payment info</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className=" row form-group text-left">
                                <div className="col-md-6 pl-0">
                                    <label htmlFor="inputFirstName" className={ errors.firstName ? 'text-danger' : '' }>First name</label>
                                    <input type="text"
                                           name="firstName"
                                           className={ errors.firstName ? 'form-control is-invalid' : 'form-control' }
                                           id="firstName"
                                           aria-describedby="firstNameHelp"
                                           placeholder="Enter first name"
                                           value={values.firstName ? values.firstName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.firstName ? errors.firstName : null}</span>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <label htmlFor="inputLastName" className={ errors.lastName ? 'text-danger' : '' }>Last name</label>
                                    <input type="text"
                                           name="lastName"
                                           className={ errors.lastName ? 'form-control is-invalid' : 'form-control' }
                                           id="lastName"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Enter last name"
                                           value={values.lastName ? values.lastName : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.lastName ? errors.lastName : null}</span>
                                </div>
                            </div>
                            <div className=" row form-group text-left">
                                <div className="col-md-8 pl-0">
                                    <label htmlFor="inputNumber" className={ errors.number ? 'text-danger' : '' }>Credit card</label>
                                    <input type="text"
                                           name="number"
                                           className={ errors.number ? 'form-control is-invalid' : 'form-control' }
                                           id="number"
                                           aria-describedby="numberHelp"
                                           placeholder="Enter credit card"
                                           value={values.number ? values.number : ""}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.number ? errors.number : null}</span>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="inputExp" className={ errors.expMonth || errors.expYear ? 'text-danger' : '' }>Expiration</label>
                                    <div className="row">
                                        <div className="col-md-5 pl-0">
                                            <input type="text"
                                                   name="expMonth"
                                                   className={ errors.expMonth ? 'form-control is-invalid' : 'form-control' }
                                                   id="expMonth"
                                                   aria-describedby="expMonthHelp"
                                                   placeholder="MM"
                                                   value={values.expMonth ? values.expMonth : ""}
                                                   onChange={handleChange}
                                            />
                                            <span className="text-danger">{errors.expMonth ? errors.expMonth : null}</span>
                                        </div>
                                        <div className="col-md-7 pl-0">
                                            <input type="text"
                                                   name="expYear"
                                                   className={ errors.expYear ? 'form-control is-invalid' : 'form-control' }
                                                   id="expYear"
                                                   aria-describedby="expYearHelp"
                                                   placeholder="YY"
                                                   value={values.expYear ? values.expYear : ""}
                                                   onChange={handleChange}
                                            />
                                            <span className="text-danger">{errors.expYear ? errors.expYear : null}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 pl-0">
                                    <Link
                                        type="cancel"
                                        className="btn btn-light col-md-3 mt-1 float-left"
                                        to="/profile"
                                    >Back</Link>
                                    <button
                                        type="submit"
                                        className="btn btn-info col-md-3 mt-1 float-right"
                                    >Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(PaymentInfoForm);