import axios from 'axios';
import { config } from '../constants/ApiConstants';
import { authHeader } from '../helpers/AuthHeader';

import { subscriptionService } from './SubscriptionService';

export const userService = {
    login,
    forgetPassword,
    register,
    confirm,
    logout,
    isLogged,
    info,
    updatePassword,
    updateSubscriptionPlan,
    invite,
    inviteAccept,
    inviteCheck,
    get,
    update
};

function login(data) {
    const requestData = {email: data.email, password: data.password};
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    //alert('userService:'+JSON.stringify(requestOptions));
    //alert('userService:'+JSON.stringify(requestData));

    return axios.post(config.apiUrl + '/auth/login', requestData, requestOptions)
        .then(handleResponseLogin)
        .catch(handleError);
}

function forgetPassword(data) {
    const requestData = {email: data.email};
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/user/lostPassword', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function register(data) {
    const requestData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        plan: data.plan
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    //alert('userService:'+JSON.stringify(requestOptions));

    return axios.post(config.apiUrl + '/user/signUp', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function confirm(data) {
    const requestData = {
        confirmationHash: data.confirmationHash
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    //alert('userService:'+JSON.stringify(requestOptions)+'|'+JSON.stringify(requestData));

    return axios.post(config.apiUrl + '/user/confirmEmail', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function logout(apiCall = false) {

    // send request to API
    if (apiCall) {
        const requestData = {};
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            timeout: 0,
            validateStatus: 0
        };

        axios.post(config.apiUrl + '/auth/logout', requestData, requestOptions)
            .then(handleResponse)
            .catch(handleError);
    }

    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.clear();
}

function isLogged() {

    const loggedUser = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null;

    if (loggedUser && loggedUser.length !== 0) {

        let curDateTime = Date.parse(new Date().toString())/1000;

        //alert(loggedUser.token.expirationDateTime+"|"+curDateTime);

        if (loggedUser.token.accessToken
            && loggedUser.token.expirationDateTime >= curDateTime
        ) {
            return true;
        } else {
            userService.logout();
            window.location.reload(true);
        }
    }
    userService.logout();

    return false;
}

function info() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/user/me', requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function updatePassword(data) {
    const requestData = {
        oldPassword: data.password,
        newPassword: data.newPassword,
        confirmedNewPassword: data.confirmNewPassword
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/user/changePassword', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function updateSubscriptionPlan(data) {
    const requestData = {
        planId: data.planId
    };
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    subscriptionService.clearStorage([])

    return axios.patch(config.apiUrl + '/user/changeSubPlan', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function invite(data) {
    const requestData = {
        email: data.email
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/user/invite', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function inviteAccept(data) {
    const requestData = data;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/user/acceptInvitation', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function inviteCheck(data) {
    const requestData = data;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.post(config.apiUrl + '/user/checkInvitation', requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function get(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.get(config.apiUrl + '/user/info/' + data.id, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function update(data) {
    const requestData = data;
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        timeout: 0,
        validateStatus: 0
    };

    return axios.patch(config.apiUrl + '/user/info/' + requestData.id, requestData, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

function handleResponseLogin(response) {

    //alert(JSON.stringify(response.data));

    if (response.data.status === 200) {

        //user.authdata = window.btoa(email + ':' + password);
        localStorage.setItem('user', JSON.stringify(response.data.data));

        return {
            'status': response.data.status,
            //'data': response.data
        };
    }
    else if (response.data.errorMessage) {

        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage
        };
    }
    else {

        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'Another error from API'
        }
    }
}

function handleResponse(response) {

    if (response.data.status === 200) {

        return {
            'status': response.data.status,
            'data': response.data.data
        };
    }
    else if (response.data.errorMessage) {

        return {
            'status': response.data.status,
            'errorMessage': response.data.errorMessage
        };
    }
    else {

        return {
            'status': response.data.status ? response.data.status : 400,
            'errorMessage': 'Another error from API'
        }
    }
}

function handleError(error) {

    //alert(JSON.stringify(error));

    return {
        'status': 401,
        'errorMessage': error.message ? error.message : 'Network problem'
    };
}