import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import './SearchForm.css';
import { groupService } from '../../services/GroupService';

function SearchForm(props) {

    const urlParams = new URLSearchParams(props.searchParams);
    const objList = props.searchList;
    const searchFields = props.searchFields;

    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [reminders, setReminders] = useState([]);
    const [searchObj, setSearchObj] = useState({group: '', reminder: ''});

    const getFieldArray = (field) => {
        let objArr;
        // eslint-disable-next-line
        (field == 'group') && (objArr = groups);
        // eslint-disable-next-line
        (field == 'reminder') && (objArr = reminders);

        return objArr;
    }

    useEffect(() => {
        let unmounted = false;
        async function getGroups() {
            const response = await groupService.list({});
            // eslint-disable-next-line
            if (!unmounted && response.status == 200) {
                setGroups(response.data.items.map(item => ({label: item.name, value: item.id})));
                setLoading(false);
            }
        }
        async function getReminders() {
            if (!unmounted) {
                setReminders([
                    {value: 'unsent', label: 'Unsent'},
                    {value: 'sent', label: 'Sent'},
                ]);
                setLoading(false);
            }
        }
        // eslint-disable-next-line
        searchFields.map(obj => {
            // eslint-disable-next-line
            (obj == 'group') && getGroups();
            // eslint-disable-next-line
            (obj == 'reminder') && getReminders();
        });
        return () => {
            unmounted = true;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSearchObj({group: urlParams.get('sg'), reminder: urlParams.get('sr')})
        // eslint-disable-next-line
    }, [])

    return(
        <Formik
            initialValues = {searchObj}
            enableReinitialize={true}
            onSubmit={(values ) => {

                let newUrl = new URLSearchParams();
                values.group && newUrl.append('sg', values.group);
                values.reminder && newUrl.append('sr', values.reminder);

                const requestData = objList + '?' + newUrl
                props.history.push(requestData);
                window.location.reload(true);

            }}
        >
            {({ handleSubmit, handleChange, values }) => (
                <div className="col-12 hv-center pr-0">
                    <Form className="" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                        <div className="input-group mt-1 mb-3">
                            {/*
                            <input type="text" className="form-control" placeholder="Search" aria-label="Search" aria-describedby="my-search" />
                            */}

                            {searchFields.map((sObj, index) => (
                                <select
                                    key={index}
                                    disabled={ loading }
                                    name={sObj}
                                    className="form-control"
                                    id={sObj}
                                    aria-describedby={sObj + "Help"}
                                    placeholder={"Select " + sObj}
                                    value={values[sObj] ? values[sObj] : ''}
                                    onChange={handleChange}
                                >
                                    <option key="" value="">All {sObj}s</option>
                                    {getFieldArray(sObj).map((obj, index) => (
                                        <option key={index} value={obj.value}>
                                            {obj.label}
                                        </option>
                                    ))}
                                </select>
                            ))}
                            <div className="input-group-append">
                                <button type="submit" className="input-group-text bg-info text-white border-info">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(SearchForm);