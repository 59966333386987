import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faBell, faFileContract } from "@fortawesome/free-solid-svg-icons";

function Home(props) {
    return(
        <div className="mt-3 mb-3">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6">
                        <p><img src="../images/sample.png" alt="" className="col img-responsive" /></p>
                    </div>
                    <div className="col-md-6">
                        <h1 className="mb-4">Keep track of...</h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id felis sit amet est condimentum bibendum non a lacus.
                            Curabitur sit amet leo mauris. Maecenas auctor augue nec velit molestie hendrerit. Nulla eleifend augue eu maximus eleifend.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                            Nullam ligula neque, gravida eu quam sit amet, fermentum semper est.
                        </p>
                        <p>
                            <Link className="btn btn-info mt-4 mb-4 pl-5 pr-5" to="/register">TRY IT OUT FOR FREE</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;