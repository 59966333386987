import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import ListTemplate from '../../helpers/List/ListTemplate'
import GroupShow from './GroupShow'

import { groupService } from '../../services/GroupService';

function GroupList(props) {

    const listSettings = {
        listTitle: 'My groups',
        listId: 'groups',
        listLinks: {
            list: '/groups',
            new: '/group/new',
            show: '/group/show/',
            edit: '/group/edit/',
            delete: '/group/del/'
        },
        pattern: [
            //{name: 'id'},
            {name: 'name', class: 'font-weight-bold'},
            {name: 'description'},
            {name: 'tasks', isCount: true},
            {name: 'reminders', isCount: true}
        ],
        templates: {
            show: GroupShow
        },
        w3dService: groupService
    };

    const listHead = [
        //{name: 'ID'},
        {name: 'Name'},
        {name: 'Description'},
        {name: 'Tasks'},
        {name: 'bell', isIcon: true},
        {name: 'Actions', class: 'text-right'}
    ];

    const [listBody, setData] = useState([]);

    useEffect(() => {
        groupService.list({storageKey: 'groupList'}).then(response => setData(response))
    }, []);

    return(
        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={listBody} />
    )
}

export default withRouter(GroupList);