import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { config } from '../../constants/ApiConstants';

import './AlertComponent.css';

function AlertComponent(props) {
    const [modalDisplay, toggleDisplay] = useState('none');
    const [modalClass, setModalClass] = useState('alert alert-success');
    const openModal = () => {
        toggleDisplay('block');
    }
    const closeModal = () => {
        toggleDisplay('none');
    }

    useEffect(() => {
        // eslint-disable-next-line
        if (props.statusMessage != undefined && props.statusMessage != "") {
            openModal()
            setModalClass(props.isError ? 'alert alert-warning' : 'alert alert-success')
        } else {
            closeModal()
        }
    }, [props]);

    return(
        <div
            className={ modalClass + ' alert-dismissable mt-4' }
            role="alert"
            id="alertPopUp"
            style={{ display: modalDisplay }}
        >
            <div className="d-flex alertMessage">
                <span>
                    {props.statusMessage ? props.statusMessage : 'Loading...'}
                    {parseInt(props.status) === parseInt(config.renewPaymentError) &&
                        <div className="mt-2">
                            <Link to="/profile">Update your payment plan and/or payment information</Link>
                        </div>
                    }
                </span>
                <button type="button" className="close" aria-label="Close" onClick={() => closeModal()}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

        </div>
    )
}

export default AlertComponent;