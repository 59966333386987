import React from 'react';
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import ListHeader from './ListHeader';
import ListBody from './ListBody';
import AlertComponent from '../../components/AlertComponent/AlertComponent'
import SearchForm from '../../components/SearchComponent/SearchForm'

import { config } from '../../constants/ApiConstants';

function ListTemplate(props) {

    const settings = props.listSettings;
    const links = settings.listLinks;
    const body = props.listBody;

    const activeType = !!props.match.params.type ? props.match.params.type : ''

    const tableClass = settings.listTableClass ? 'table table-striped mt-4 ' + settings.listTableClass : 'table table-striped mt-4'

    const onRedirectClick = (link) => {
        props.history.push(link)
        window.location.reload(true)
    }

    return (
        <div className="mt-2">
            <h2>{settings.listTitle}
                {
                    // eslint-disable-next-line
                    links.new &&
                        // eslint-disable-next-line
                        body.status == config.renewPaymentError || body.status !== 200 ?
                            <Link className="btn btn-light ml-2" to="#"><FontAwesomeIcon icon={faPlus}/></Link>
                        :
                            links.new &&
                                <Link className="btn btn-info ml-2" to={links.new}><FontAwesomeIcon icon={faPlus}/></Link>
                }
                {
                    links.customTitleLink &&
                        links.customTitleLink.map((itemLink, index) =>
                            (<Link className={activeType === itemLink.id ? "btn btn-info ml-2 active" : "btn btn-info ml-2" }  to="#" onClick={() => onRedirectClick(itemLink.link)} key={index}>{itemLink.name}</Link>)
                        )
                }

                {
                    settings.listSearch &&
                        <div className="float-right">
                            <SearchForm searchParams={settings.listSearch.searchParams} searchFields={settings.listSearch.searchFields} searchList={settings.listLinks.list} />
                        </div>
                }
            </h2>
            <div className="content">
                {
                    body.status !== 200 ?
                        <AlertComponent statusMessage={body.errorMessage} status={body.status} isError="true" />
                    :
                        <table className={tableClass} id={settings.listId}>
                            <ListHeader listId={settings.listId} cols={props.listHead} />
                            <ListBody settings={settings} cols={body.data} links={links} />
                        </table>

                }
            </div>
        </div>
    );
}

export default withRouter(ListTemplate);