import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import './RegistrationForm.css';
import { userService } from '../../services/UserService';

const registerValidationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    email: Yup.string().email("Not valid Email").required("Email is required"),
    password: Yup.string().min(8).required("Password is required"),
    confirmPassword: Yup.string().min(8).required("Confirm is required").oneOf([Yup.ref('password'), null], "Password doesn't match"),
    acceptTerms: Yup.boolean().required("Please accept Terms and Conditions")
});
const apiErrors = [];

function RegistrationForm(props) {
    const redirectToSuccess = (message) => {
        props.history.push('/success/' + message);
        window.location.reload(true);
    }
    const redirectToLogin = () => {
        props.history.push('/login');
    }
    return(
        <Formik
            initialValues={{ email: "", password: "", firstName: "", lastName: ""}}
            validationSchema={registerValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    email: values.email,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    plan: 10
                }
                userService.register(requestData)
                    .then(function (response) {
                        if(response.status === 200){
                            redirectToSuccess("The confirmation Email for your registration is sent to given Email address. Please verify your Email in order to login.");
                        } else if (response.errorMessage){
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Please enter valid information');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                    <div className="card login-card col-lg-4 hv-center pt-4 pb-4">
                        <h2 className="mb-5">Registration</h2>
                        <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                            <div className="form-group form-row text-left">
                                <div className="col">
                                    <label htmlFor="inputFirstName" className={ errors.firstName ? 'text-danger' : '' }>First name</label>
                                    <input type="firstName"
                                           name="firstName"
                                           className={ errors.firstName ? 'form-control is-invalid' : 'form-control' }
                                           id="firstName"
                                           aria-describedby="firstNameHelp"
                                           placeholder="Enter first name"
                                           value={values.firstName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.firstName ? errors.firstName : null}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputLastName" className={ errors.lastName ? 'text-danger' : '' }>Last name</label>
                                    <input type="lastName"
                                           name="lastName"
                                           className="form-control"
                                           id="lastName"
                                           aria-describedby="lastNameHelp"
                                           placeholder="Enter last name"
                                           value={values.lastName}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.lastName ? errors.lastName : null}</span>
                                </div>
                            </div>
                            <div className="form-group text-left">
                                <label htmlFor="inputEmail" className={ errors.email ? 'text-danger' : '' }>Email</label>
                                <input type="email"
                                       name="email"
                                       className={ errors.email ? 'form-control is-invalid' : 'form-control' }
                                       id="email"
                                       aria-describedby="emailHelp"
                                       placeholder="Enter email"
                                       value={values.email}
                                       onChange={handleChange}
                                />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                <span className="text-danger">{errors.email ? errors.email : null}</span>
                            </div>
                            <div className="form-group form-row text-left">
                                <div className="col">
                                    <label htmlFor="inputPassword" className={ errors.password ? 'text-danger' : '' }>Password</label>
                                    <input type="password"
                                           name="password"
                                           autoComplete="no"
                                           className={ errors.password ? 'form-control is-invalid' : 'form-control' }
                                           id="password"
                                           placeholder="Password"
                                           value={values.password}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.password ? errors.password : null}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="inputConffirmPassword" className={ errors.confirmPassword ? 'text-danger' : '' }>Confirm Password</label>
                                    <input type="password"
                                           name="confirmPassword"
                                           autoComplete="no"
                                           className={ errors.confirmPassword ? 'form-control is-invalid' : 'form-control' }
                                           id="confirmPassword"
                                           placeholder="Confirm Password"
                                           value={values.confirmPassword}
                                           onChange={handleChange}
                                    />
                                    <span className="text-danger">{errors.confirmPassword ? errors.confirmPassword : null}</span>
                                </div>
                            </div>
                            <div className="form-group text-left">
                                <input type="checkbox"
                                       name="acceptTerms"
                                       className={ errors.acceptTerms ? 'mr-2 is-invalid' : 'mr-2' }
                                       id="acceptTerms"
                                       value={values.acceptTerms}
                                       onChange={handleChange}
                                />
                                <label htmlFor="checkboxAcceptTerms" className={ errors.acceptTerms ? 'text-danger' : '' }>I Accept the <Link to="/privacy" target="_blank">Terms and Conditions</Link></label>
                                <br/><span className="text-danger">{errors.acceptTerms ? errors.acceptTerms : null}</span>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-info col-md-12"
                            >
                                Register
                            </button>
                        </form>
                        <div className="mt-3">
                            <span className="loginText" title="Login" onClick={() => redirectToLogin("")}>Already have an account?</span>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default withRouter(RegistrationForm);