import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import Moment from 'moment';

import ListTemplate from '../../helpers/List/ListTemplate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { reminderService } from '../../services/ReminderService';
import BigCalendar from '../../components/Calendar/BigCalendar';

function Dashboard(props) {

    const listSettings = {
        listTitle: '',
        listId: 'last5Reminders',
        listTableClass: 'table-sm',
        listLinks: {
            list: '/reminders',
            delete: '/reminder/del/'
        },
        pattern: [
            {name: 'reminderDate', isDate: true, class: 'text-info font-weight-bold'},
            {name: 'sentDate', isDate: true, class: 'text-success font-weight-bold'},
            {name: 'message', class: 'font-weight-bold'},
            {name: 'task'}
        ],
        w3dService: reminderService
    };

    const listHead = [];

    const [lastTenRemindersBody, setLastRemindersData] = useState([]);
    const [lastTenSentRemindersBody, setLastSentRemindersData] = useState([]);
    const [allEvents, setEvents] = useState([]);

    const itemsInList = 10;
    useEffect(() => {
        reminderService.list({limit: itemsInList, type: 'unsent', storageKey: 'remListLast10Unsent'}).then(response => setLastRemindersData(response))
        reminderService.list({limit: itemsInList, type: 'sent', storageKey: 'remListLast10Sent'}).then(response => setLastSentRemindersData(response))
    }, []);

    useEffect(() => {
        async function getEvents() {
            const tt = await reminderService.list({storageKey: 'remListAll'});

            if (tt.status === 200) {
                const ett = tt.data.items.map(
                    item => (
                        {
                            id: item.id,
                            title: item.message,
                            allDay: true,
                            start: new Date(Moment(item.reminderDate.date).toLocaleString()),
                            end: new Date(Moment(item.reminderDate.date).toLocaleString()),
                        }
                    )
                )
                setEvents(ett)
            }
        }
        getEvents();
    }, []);

    return(
        <div className="mt-2">
            <div className="content">
                <div className="row mb-5 hv-center">
                    <div className="col-lg-3 mt-2">
                        <Link className="btn btn-info hv-center p-4" to="/group/new">
                            <h1 className="card-title mb-0 text-white">
                                <FontAwesomeIcon icon={faPlus} className="mr-3" />
                                Group
                            </h1>
                        </Link>
                    </div>
                    <div className="col-lg-4 mt-2">
                        <Link className="btn btn-info hv-center p-4" to="/task/new">
                            <h1 className="card-title mb-0 text-white">
                                <FontAwesomeIcon icon={faPlus} className="mr-3" />
                                Task / Reminder
                            </h1>
                        </Link>
                    </div>
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col-md-12">
                        <BigCalendar myEvents={allEvents} />
                    </div>
                </div>

                <h3 className="font-italic">{ itemsInList } Upcoming Reminders</h3>
                <div className="card pl-3 pr-3 pt-0 pb-0 mb-3 w-100 bg-light">
                    <div className="card-text pt-0">
                        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={lastTenRemindersBody} />
                    </div>
                </div>

                <h3 className="font-italic">{ itemsInList } Last Sent Reminders</h3>
                <div className="card pl-3 pr-3 pt-0 pb-0 mb-3 w-100 bg-light">
                    <div className="card-text pt-0">
                        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={lastTenSentRemindersBody} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Dashboard);