import React from 'react';

function Privacy(props) {
    return(
        <div className="mt-2">
            <div className="container">
                <h2 className="mb-4">Privacy Policy</h2>

                <p className="font-italic">W3D Remind me! Privacy Policy
                <br/>Last Updated: May, 2020</p>
                <p>This is the Privacy Policy for the Web site ("Site") for W3D Remind me!. Your privacy is very important to us and we are committed to protecting it. We understand that you need to be in control of your personal data. This Privacy Policy explains the manner in which the W3D Remind me! in collecting personal data from you and the purpose for that collection. This Privacy Policy is designed to ensure that you are aware of how your personal data is being used and to provide you with choices about that use. By visiting the W3D Remind me! Site or submitting your personal data, you accept the terms described in this Privacy Policy. From time to time we may modify these rules. We will notify you of any significant change by posting a notice on our home page and information collection forms. Your continued use of the W3D Remind me! Site after any modification indicates your agreement to the new terms.</p>
                <h5 className="pt-3">Security</h5>
                <p>W3D Remind me! is concerned with the security of your personal data. Your personally identifiable information is kept secure. Only authorized employees, agents and contractors (who have agreed to keep information secure and confidential) have access to this information. </p>

                <h5 className="pt-3">Anonymous Information</h5>
                <p>Cookies. A cookie is a string of information that is sent by a Web site and stored on your hard drive or temporarily in your computer's memory. We may employ cookie technology to help you move faster through our Site. We may employ cookie technology to estimate our total audience size and traffic and to help us improve our Site's experience by measuring which site areas are of greatest interest to users. You can turn off the ability to receive any of these cookies by adjusting the browser in your computer, but you should note that if you do so, this may materially affect the functionality of the Site and the information you can access through it. If you wish to find out more about cookies, or how to refuse cookies, please visit the Interactive Advertising Bureau's web site at www.allaboutcookies.org. We collect anonymous data when you visit most pages on the W3D Remind me! Site. Your visit may automatically provide us with data that is not linked to your personal information, such as your IP (Internet Protocol) address, browser type, operating system, domain name, access times, and referring web site addresses. We use your anonymous data to obtain general statistics regarding the use of the W3D Remind me! Site and its specific Web pages and to evaluate how our visitors use and navigate our Web site on an aggregate basis. We do not link your anonymous data with personal data.</p>

                <h5 className="pt-3">Links to Other Web Sites</h5>
                <p>We may make content or services from other web sites including our affiliated or co-branded web sites, available to you from links located on this site. These other web sites are not subject to this Privacy Policy. We recommend that you review the Privacy Policy at each such web site to determine how that site protects your privacy.</p>

                <h5 className="pt-3">Copyright</h5>
                <p>W3D Remind me! is a website that gives his users a plattform for information sharing. it is absolutely forbidden to upload any copyright protected information or material, unless you are autorzied to do so. at the same time we are not, at any way or in any form, responsible for the Information being uploaded to our website by the Users. If you sure there are materials that have copyright issues please contact us that we can remove it. &nbsp;</p>

                <h5 className="pt-3">Trademarks</h5>
                <p>W3D Remind me!, the W3D Remind me! Logo, and any other trademarks on the W3D Remind me! Site are trademarks owned by W3D Remind me!. You may not use the W3D Remind me! Trademarks in connection with any product or service that does not belong to us, or in any manner that is likely to cause confusion among users about whether we are the source, sponsor, or endorser of the product or service, or in any manner that disparages or discredits us.</p>

                <h5 className="pt-3">Linked Websites And Advertising</h5>
                <p>As a convenience to users, we may provide links to third-party content, websites or services. We do not endorse, sponsor, control, or otherwise accept responsibility for this material, except to the extent that we otherwise control it. You should be aware that third-party websites are not covered by this Agreement or our Privacy Policy, and we therefore cannot be responsible for the content or practices of any such website, even if it links to the Site or even if the website is operated by a company affiliated or otherwise connected with us.</p>
                <p>IN NO EVENT WILL WE, OUR PARENTS OR AFFILIATES, OR ANY OF OUR RESPECTIVE INVESTORS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS AND SUPPLIERS (COLLECTIVELY, THE "INDEMNIFIED PARTIES"), BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES, INCLUDING LOSS OF PROFITS, ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF THE SITE (INCLUDING ANY INFORMATION, PRODUCTS OR SERVICES ADVERTISED IN, OBTAINED ON OR PROVIDED THROUGH THE SITE OR CONTENT CREATED ON OR THROUGH THE SITE). &nbsp;</p>
                <p>THE SITE IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                <p>THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF THE SERVICES, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, GROSS NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION, EVEN IF THE CLAIM ALLEGES THAT THE INDEMNIFIED PARTIES' CONDUCT WAS WILLFUL.</p>

                <h5 className="pt-3">Integration; No Waiver; Severability; Termination</h5>
                <p>This Agreement, along with the Privacy Policy incorporated within it by reference, constitutes the entire agreement between you and us. They govern your use of the Site (but excluding services, if any, that we may provide to you under a separate written agreement), and completely replace any prior agreements between you and us in relation to the Site.</p>
                <p>Even if we act in a way that you believe to be inconsistent with this Agreement, those actions will not be deemed a waiver or constructive amendment of this Agreement. Similarly, our failure to object to your breach of your obligations under this Agreement does not constitute a waiver of any of our rights. &nbsp;</p>
                <p>If any provision of this Agreement is ruled unlawful, void, or unenforceable, that provision will be severed from this Agreement, and the other provisions will remain effective and enforceable. In addition, the severed provision will be deemed replaced with a comparable provision that most closely reflects the intent of the parties.</p>
                <p>We reserve the right to terminate the Site and this User Agreement at any time without notice for any reason, including, in the case of the User Agreement, for your violation of any of its provisions. The Disclaimer and Limitation of Liability and Governing Law sections of this User Agreement shall survive any such termination.</p>
                <p>Welcome to the W3D Remind me! website (the "Site"), This document (the "User Agreement" or the "Agreement") is a legal contract that describes the conditions under which users ("you") are permitted to use the Site.</p>
                <p>By using the Site, you agree to be bound by this contract. If you do not accept the Agreement, do not use the Site.</p>

                <h5 className="pt-3">Changes To The Agreement</h5>
                <p>We may change this Agreement at any time, so we encourage you to review it periodically before using the Site. To assist you in doing this, we will make the most recent version of the Agreement available on the Site, and we will indicate at the top of the Agreement the most recent date when it was modified.</p>
                <p>By continuing to use the Site after we modify the Agreement, you accept the new version of the Agreement. Therefore, it is important that you read this page regularly to ensure you are familiar with the most updated Agreement. If you do not agree to the changes, you should not continue to use the Site.</p>

                <h5 className="pt-3">Scope Of License</h5>
                <p>In exchange for your acceptance of this Agreement, you are authorized to use the Site, but only for personal and noncommercial purposes. In particular, you cannot use the Site to generate revenue or to distribute or redistribute any portion of the Site.</p>
                <p>The Site contains material that is protected by copyright, trademark, or other intellectual property rights, and the Site itself is protected. You may not download, modify, publish, transmit, create derivative works from, or in any way exploit, any component of the Site.</p>
                <p>You may link to the home page of the Site as long as the link does not cast us in a false or misleading light.</p>
                <p>You may not frame the content of the Informational Site. You may not use metatags or any other "hidden text" that incorporates the W3D Remind me! or our name without our express written consent.</p>
                <p>You may not share your Access Data to another person, the is just for personal and individual use and Registered Accounts can not be shared with others.</p>
                <p>The Site is intended for the use of adults 18 years or older.</p>

                <h5 className="pt-3">Security</h5>
                <p>In exchange for your access to the Site, you agree not to take any steps that are intended to or could damage, inhibit, or prevent operation of the Site or that could cause injury to yourself, to us, or to anyone else.</p>

                <p className="font-weight-bold font-italic">Without limitation, you agree not to attempt to:</p>
                <ol>
                    <li>
                        <p>Modify the Site or make it possible for you or someone else to access the Site without using an interface that we provide (including through the use of spiders or robots, except to facilitate the creation of public search engines that catalog publicly accessible portions of the Site);</p>
                    </li>
                    <li>
                        <p>Introduce viruses or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
                    </li>
                    <li>
                        <p>Damage, disable, overburden, impair, or gain unauthorized access to the Site or our servers, computer network, or user accounts;</p>
                    </li>
                    <li>
                        <p>Collect information about other users for the purpose of sending, or to facilitate or encourage the sending of, unsolicited bulk or other communications, or for any other purpose not explicitly authorized by us;</p>
                    </li>
                    <li>
                        <p>Falsify, conceal, or modify information identifying yourself or another user, including Internet Protocol header information;</p>
                    </li>
                    <li>
                        <p>Use our servers, networks, or other facilities for any purpose not explicitly authorized by us, including for the transmission of messages not authorized by us, or to interfere with or cause damage to the Site or any other service operated by us or any other party; or</p>
                    </li>
                    <li>
                        <p>Take any other action that we, in our exclusive discretion, believe may damage or injure you, us, or any third party </p>
                    </li>
                </ol>

                <p>Enjoy Your Time At W3D Remind me!</p>
            </div>
        </div>
    )
}

export default Privacy;