import React from 'react';

function About(props) {
    return(
        <div className="mt-2">
            <div className="container">
                <h2 className="mb-4">About Us</h2>
                <div className="row">
                    <div className="col-md-6">
                        <h5>What we do</h5>
                        <p>
                            W3DSoft Ltd is a digital company, part of a Multinational Group operating in different countries all over the world. We offer innovative, modular and scalable IT solutions to the different sector.
                            <br/>Our aim is to connect people and sectors and make their work <span className="font-weight-bold">simple, fast and digital</span>. In order to achieve that, our young, dynamic and diverse teams of developers, business experts and product developers are working together closely, constantly implementing feedback gained from customers and data, to create the solutions our customers really need.
                            We bring together market knowledge, market insight and new technologies to offer efficient and valuable services and help our partners grow. Fairness and respect to our customers are core values of ours.
                        </p>
                        <p>&nbsp;</p>
                        <h5>Our locations & offices</h5>
                        <p>Our offices are located in Europe. Our business developers and commercial teams are based in Germany.
                            <br/>W3DSoft Ltd solution architects and Quality Assurance experts operate from Germany.
                            Our team of IT developers is based in Bulgaria. More than 80% of them are senior developers with proven knowledge and relevant experience.
                        </p>
                        <div className="row">
                            <div className="col">
                                <img src="../images/map.png" alt="" className="col img-responsive" />
                            </div>
                        </div>
                        <p>&nbsp;</p>
                    </div>
                    <div className="col-md-6">
                        <h5>Why we do it</h5>
                        <p>
                            The revolution of the technology started spreading around the world, <span className="font-weight-bold">affecting a high amount of processes</span> in different sectors and life.
                            Digitalisation is expected to reshape the economic and social lives of people and to <span className="font-weight-bold">positively impact</span> their processes and interactions.
                            We strive to be <span className="font-weight-bold">the answer for each demand</span>!
                        </p>
                        <p>&nbsp;</p>
                        <h5>How it works</h5>
                        <div>Our work comes together like <span className="font-weight-bold">pieces of a puzzle</span>. We combine those different, but compatible pieces and as result we turn their mutual harmony into a complex, but wholesome picture.
                            Through modularity, integration and development, we strive for <span className="font-weight-bold">continuous improvement</span>.
                            <div className="row mt-4 ml-0 mr-2 text-left">
                                <div className="card p-4 mb-3 w-100 bg-w3ds">
                                    <h5 className="card-title text-info mb-0">
                                        I. Scoping
                                        <span className="col-lg-8 pl-0 small text-dark float-right">Good understanding of the needs and wishes of the partner / customer.</span>
                                    </h5>
                                </div>
                                <div className="card p-4 mb-3 w-100 bg-w3ds">
                                    <h5 className="card-title text-primary mb-0">
                                        II. Customization
                                        <span className="col-lg-8 pl-0 small text-dark float-right">Help partners / customers to create edge over the competitors.</span>
                                    </h5>
                                </div>
                                <div className="card p-4 mb-3 w-100 h-center bg-w3ds">
                                    <h5 className="card-title text-danger mb-0">
                                        III. Testing
                                        <span className="col-lg-8 pl-0 small text-dark float-right">Customers / Partners verification of the created solution.</span>
                                    </h5>
                                </div>
                                <div className="card p-4 mb-3 w-100 bg-w3ds">
                                    <h5 className="card-title mb-0">
                                        IV. Integration
                                        <span className="col-lg-8 pl-0 small float-right">Easy, simple, fast and fully automated integration.</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;