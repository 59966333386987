import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { userService } from '../../services/UserService';

function RegistrationConfirm(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        userService.confirm({confirmationHash: props.match.params.confirmationHash})
            .then(
                response => setData(response)
            )
    }, [props.match.params.confirmationHash]);

    return(
        <div className="col-12 mt-3 mb-2 hv-center">
            {
                data.errorMessage ?
                    <div className="card col-md-5 alert alert-warning">
                        <h4>{ data.errorMessage }</h4>
                        <br/><Link to="/support">Contact us</Link>
                    </div>
                :
                    <div className="card col-md-5 alert alert-success">
                        <h4>Confirmation of the registration is successful!</h4>
                        <br/><Link to="/login">Go to login</Link>
                    </div>
            }
        </div>
    )
}

export default RegistrationConfirm;