import React from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";

import './LoginForm.css';
import { userService } from '../../services/UserService';

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Not valid Email").required("Email is required"),
    password: Yup.string().min(8).required("Password is required")
});
const apiErrors = [];

function LoginForm(props) {
    const redirectToDashboard = () => {
        props.history.push('/dashboard');
        window.location.reload(true);
    }
    const redirectToRegister = () => {
        props.history.push('/register');
    }
    /*
    const redirectToForgetPassword = () => {
        props.history.push('/forgetPassword');
        //window.location.reload(true);
    }
    */
    return(
        <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidationSchema}
            onSubmit={(values, { setFieldError }) => {
                const requestData = {
                    email: values.email,
                    password: values.password
                }
                userService.login(requestData)
                    .then(function (response) {
                        if (response.status === 200) {
                            redirectToDashboard();
                        } else if (response.errorMessage) {
                            setFieldError('email', response.errorMessage);
                            apiErrors.push('email');
                        } else {
                            setFieldError('email', 'API - Not valid credentials');
                            apiErrors.push('email');
                        }
                    })
            }}
            validate={values => {
                let errors = {};
                if (apiErrors.includes(values.email)) {
                    errors.email = "API - Error with Email";
                }
                return errors;
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <div className="col-12 mt-2 mb-2 hv-center">
                <div className="card login-card col-lg-3 hv-center">
                    <h2 className="mb-5">Login</h2>
                    <form className="col-md-10" formNoValidate="formNoValidate" onSubmit={handleSubmit}>
                        <div className="form-group text-left">
                            <label htmlFor="inputEmail" className={ errors.email ? 'text-danger' : '' }>Email</label>
                            <input type="text"
                                   name="email"
                                   className={ errors.email ? 'form-control is-invalid' : 'form-control' }
                                   id="email"
                                   aria-describedby="emailHelp"
                                   placeholder="Enter email"
                                   value={values.email}
                                   onChange={handleChange}
                            />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            <span className="text-danger">{errors.email ? errors.email : null}</span>
                        </div>
                        <div className="form-group text-left">
                            <label htmlFor="inputPassword" className={ errors.password ? 'text-danger' : '' }>Password</label>
                            <input type="password"
                                   name="password"
                                   autoComplete="no"
                                   className={ errors.password ? 'form-control is-invalid' : 'form-control' }
                                   id="password"
                                   placeholder="Password"
                                   value={values.password}
                                   onChange={handleChange}
                            />
                            <span className="text-danger">{errors.password ? errors.password : null}</span>
                        </div>
                        <div className="form-check">
                        </div>
                        <button
                            type="submit"
                            className="btn btn-info col-md-12 mt-1"
                        >Submit</button>
                    </form>
                    <div className="mt-3">
                        <span className="loginText mr-2" title="Registration" onClick={() => redirectToRegister()}>New in the application?</span>
                        {/*
                        <span className="loginText text-right" title="Renew your password" onClick={() => redirectToForgetPassword() }>Forget your password?</span>
                        */}
                    </div>
                </div>
            </div>
            )}
        </Formik>
    )
}

export default withRouter(LoginForm);