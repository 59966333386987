import React from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import Moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

function BigCalendar(props) {
    const localizer = momentLocalizer(Moment)
    let allViews = Object.keys(Views).map(k => Views[k])
    const calendarHeight = props.height ? props.height : 700
    const calendarEvents = props.myEvents ? props.myEvents : []

    return (
        <Calendar
            localizer={localizer}
            events={calendarEvents}
            defaultDate={new Date()}
            views={allViews}
            defaultView={'month'}
            showMultiDayTimes
            style={{ height: calendarHeight }}
        />
    );
}

export default BigCalendar;