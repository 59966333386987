import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrash, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import ModalPopup from '../../components/ModalComponent/ModalComponent';

function ListItem(props) {

    const record = props.record;
    const links = props.links;
    const pattern = props.pattern;

    const [isSending, setIsSending] = useState(false)
    const sendDelRequest = async () => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        await props.w3dService.del({id: record.id})
        // once the request is sent, update state again
        setIsSending(false)
        window.location.reload(true);
    };

    const recordRow = pattern.map((pCol, index) =>
        pCol.name in record ?
            pCol.name === 'id' ?
                <th scope="row" key={index} className={pCol.class}>{record[pCol.name]}</th>
            :
                pCol.isCount ?
                    <td key={index} className={pCol.class}>{record[pCol.name].length}</td>
                :
                    pCol.isDate ?
                        <td key={index} className={pCol.class}>{record[pCol.name] ? Moment(record[pCol.name].date).format('DD.MM.YYYY') : ''}</td>
                    :
                        pCol.isBool ?
                            <td key={index} className={pCol.class}>{record[pCol.name] ? <FontAwesomeIcon className="text-success" icon={faCheck} /> : <FontAwesomeIcon className="text-danger" icon={faTimes} /> }</td>
                            :
                            <td key={index} className={pCol.class}>{record[pCol.name]}</td>
        :
            <td key={index}>&nbsp;</td>
    );

    const [modalShow, setModalShow] = useState(false);
    const [modalDelShow, setModalDelShow] = useState(false);

    return (
        <tr>
            { recordRow }
            <td className="text-right">
                {links.show &&
                    <>
                    <Link to="#" onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faEye} /></Link>
                    <ModalPopup record={record} show={modalShow} template={props.templates ? props.templates.show : ''} onHide={() => setModalShow(false)} />
                    </>
                }
                {links.edit &&
                    <Link className="ml-2" to={links.edit + record.id}><FontAwesomeIcon icon={faPen} /></Link>
                }
                {links.delete &&
                    <>
                    <Link className="ml-2" disabled={isSending} to='#' onClick={() => setModalDelShow(true)}><FontAwesomeIcon icon={faTrash} /></Link>
                    <ModalPopup record={record} show={modalDelShow} onHide={() => setModalDelShow(false)} taskId={'delObjTask' + record.id} isDelete="true" deleteLink={sendDelRequest} />
                    </>
                }
            </td>
        </tr>
    );
}

export default withRouter(ListItem);