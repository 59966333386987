import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ModalComponent(props) {

    const record = props.record;

    return(
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            id={props.taskId ? props.taskId : "objTask" + record.id}
            dialogClassName="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        record.name ?
                            props.isDelete ? 'Delete: ' + record.name : 'Show: ' + record.name
                        :
                            props.isDelete ? 'Delete element' : 'Show element'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.template ?
                        <props.template record={record} key={'showPopup' + record.id} />
                    :
                        props.isDelete ?
                            'Are you sure you want to delete this element?'
                        :
                            JSON.stringify(record)
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" className="float-left" onClick={props.onHide}>
                    Close
                </Button>
                { props.isDelete &&
                    <Button variant="primary" onClick={props.deleteLink}>
                        OK
                    </Button>
                }
             </Modal.Footer>

        </Modal>
    )
}

export default ModalComponent;