import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import ListTemplate from '../../helpers/List/ListTemplate'
import TaskShow from './TaskShow'

import { taskService } from '../../services/TaskService';

function TaskList(props) {

    const urlParams = new URLSearchParams(props.location.search)
    // eslint-disable-next-line
    const storageListKey = urlParams != "" ? 'taskListSearch' : 'taskList';

    /* ID and Actions should not be included in pattern in listSetting */
    const listSettings = {
        listTitle: 'My tasks',
        listId: 'tasks',
        listLinks: {
            list: '/tasks',
            new: '/task/new',
            show: '/task/show/',
            edit: '/task/edit/',
            delete: '/task/del/'
        },
        listSearch: {
            searchParams: urlParams,
            searchFields: ['group']
        },
        pattern: [
            //{name: 'id'},
            {name: 'name', class: 'font-weight-bold'},
            {name: 'groupName'},
            {name: 'description'},
            {name: 'startDate', isDate: true, class: 'font-weight-bold'},
            {name: 'endDate', isDate: true, class: 'font-weight-bold'},
            {name: 'reminders', isCount: true}
        ],
        templates: {
            show: TaskShow
        },
        w3dService: taskService
    };

    const listHead = [
        //{name: 'ID'},
        {name: 'Name'},
        {name: 'Group'},
        {name: 'Description'},
        {name: 'Start date'},
        {name: 'End date'},
        {name: 'bell', isIcon: true},
        {name: 'Actions', class: 'text-right'}
    ];

    const [listBody, setData] = useState([]);

    useEffect(() => {
        taskService.list({linkParams: urlParams, storageKey: storageListKey}).then(response => setData(response))
        // eslint-disable-next-line
    }, []);


    return(
        <ListTemplate listSettings={listSettings} listHead={listHead} listBody={listBody} />
    )
}

export default withRouter(TaskList);